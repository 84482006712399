import React, { useContext, useEffect, useState } from "react";

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
	Chip,
	Divider,
	FormControl,
	Grid,
	Select,
	Tooltip
} from "@mui/material";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "date-fns/locale";
import { makeStyles } from 'tss-react/mui';
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import { MuiColorInput } from "mui-color-input";
import { AuthContext } from "../../context/Auth/AuthContext";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";


import WatchLaterIcon from '@mui/icons-material/WatchLater';
import QueueScheduleComponent from "../QueueScheduleComponent";

const useStyles = makeStyles()((theme) => ({
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const QueueSchemaChatbot = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	color: Yup.string().required(),
});

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	color: Yup.string().required(),
	greetingMessage: Yup.string().required("Obrigatório"),
	messageAccepting: Yup.string().required("Obrigatório"),
	closedMessage: Yup.string().required("Obrigatório"),
	description: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	companyId: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {

	const { user: loggedInUser } = useContext(AuthContext);
	const classes = useStyles();

	const initialState = {
		companyId: loggedInUser.companyId,
		name: "",
		color: "",
		isChatbot: false,
		chatbotId: "",
		greetingMessage: "",
		messageAccepting: "",
		closedMessage: "",
		description: "",
	};

	const defaultStart = new Date(2024, 9, 25, 8, 0);
	const defaultEnd = new Date(2024, 9, 25, 18, 0);
	const defaultIntervalStart = new Date(2024, 9, 25, 12, 0);
	const defaultIntervalEnd = new Date(2024, 9, 25, 14, 0);

	const daysOfWeek = [
		'sunday',
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday'
	];

	const initialStateSchedule = daysOfWeek.reduce((schedule, day) => {
		schedule[day] = {
			open: day === 'saturday' || day === 'sunday' ? false : true,
			start: defaultStart,
			end: defaultEnd,
			interval: true,
			intervalStart: defaultIntervalStart,
			intervalEnd: defaultIntervalEnd
		};
		return schedule;
	}, {});

	const [chatbot, setChatbot] = useState(false);
	const [chatbotToQueue, setChatbotToQueue] = useState('');
	const [queue, setQueue] = useState(initialState);
	const [scheduleQueue, setScheduleQueue] = useState(initialStateSchedule);
	const [tab, setTab] = useState('1');
	const [chatbots, setChatbots] = useState([]);

	useEffect(() => {
		(async () => {
			setChatbotToQueue('');
			try {
				const { data } = await api.get(`/chatbot/`);
				setChatbots(data);
			} catch (err) {
				ToastError(err);
			}
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				setQueue(prevState => ({ ...prevState, ...data }));
				if (data?.schedules) { 
					const queueSchedules = data.schedules;

					setScheduleQueue(queueSchedules) 
				}
				setChatbot(data.isChatbot);
				if (data?.chatbotId) setChatbotToQueue(data.chatbotId);
			} catch (err) {
				ToastError(err);
			}
		})();

		return () => {
			setQueue(initialState);
			setTab('1');
		};
	}, [queueId, open]);

	const handleClose = () => {
		onClose();
		setQueue(initialState);
		setTab('1');
	};

	const handleSaveQueue = async (values) => {
		try {
			if (queueId) {
				await api.put(`/queue/${queueId}`, values);
			} else {
				await api.post("/queue", values);
			}
			ToastSuccess("Queue saved successfully");
			handleClose();
		} catch (err) {
			ToastError(err);
		}
	};

	const handleChangeTab = (e, newValue) => setTab(newValue);

	const handleChangeValues = (e, callback) => {
		const { name, value } = e.target;
		setQueue((prevState) => ({ ...prevState, [name]: value }));
		callback(e)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			scroll="body"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
			fullWidth
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "900px",
					},
				},
			}}
		>
			<DialogTitle>
				{queueId ? `${i18n.t("queueModal.title.edit")}` : `${i18n.t("queueModal.title.add")}`}
			</DialogTitle>
			<Formik
				initialValues={queue}
				enableReinitialize={true}
				validationSchema={chatbot ? QueueSchemaChatbot : QueueSchema}
				onSubmit={(values, actions) => {
					setTimeout(() => {
						let value = { ...values, schedules: scheduleQueue };
						handleSaveQueue(value);
						actions.setSubmitting(false);
					}, 400);
				}}
			>
				{({ values, handleChange, errors, touched, isSubmitting }) => (
					<Form>
						<TabContext value={tab}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList onChange={handleChangeTab} aria-label="tabsEditQueues" centered>
									<Tab icon={<FormatListNumberedIcon />} iconPosition="start" label={i18n.t("queueModal.form.tabs.information")} value="1" />
									<Tab icon={<AccessTimeIcon />} iconPosition="end" label={i18n.t("queueModal.form.tabs.schedules")} value="2" />
								</TabList>
							</Box>
							<TabPanel value="1">
								<DialogContent dividers>
									<Field style={{ display: "none" }} as={TextField} type="hidden" name="companyId" value={loggedInUser.companyId} />
									<Grid container spacing={1}>
										<Grid item xs={12} sm={8}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("queueModal.form.name")}
												name="name"
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												value={queue.name}
												fullWidth
												InputLabelProps={{ shrink: true }}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<MuiColorInput
												format="hex"
												sx={{ marginTop: '8px' }}
												label="Cor do agendamento"
												name="color"
												size="small"
												value={queue.color}
												onChange={color => setQueue((prev) => ({ ...prev, color }))}
												variant="outlined"
												margin="dense"
												error={touched.color && Boolean(errors.color)}
												helperText={touched.color && errors.color}
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										</Grid>
										<Grid item xs={12}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("queueModal.form.description")}
												name="description"
												error={touched.description && Boolean(errors.description)}
												helperText={touched.description && errors.description}
												variant="outlined"
												margin="dense"
												value={queue.description}
												multiline
												minRows={2}
												maxRows={10}
												fullWidth
												InputLabelProps={{ shrink: true }}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												label={i18n.t("queueModal.chatbot.use")}
												control={<Checkbox
													checked={chatbot}
													onChange={() => setChatbot(!chatbot)}
													inputProps={{ 'aria-label': 'controlled' }}
												/>}
											/>
										</Grid>
										{chatbot && (
											<Grid item xs={12}>
												<FormControl
													fullWidth
													InputLabelProps={{ shrink: true }}
													size="small"
												>
													<InputLabel id="demo-simple-select-helper-label">{i18n.t("queueModal.chatbot.select")}</InputLabel>
													<Select
														labelId="demo-simple-select-helper-label"
														id="demo-simple-select-helper"
														value={chatbotToQueue}
														label={i18n.t("queueModal.chatbot.select")}
														onChange={(e) => setChatbotToQueue(e.target.value)}
													>
														{chatbots.map((chatbot) => (
															<MenuItem key={chatbot.id} value={chatbot.id}>{chatbot.name}</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										)}
										<Grid item xs={12} sm={6}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("queueModal.form.greetingMessage")}
												name="greetingMessage"
												error={touched.greetingMessage && Boolean(errors.greetingMessage)}
												helperText={touched.greetingMessage && errors.greetingMessage}
												variant="outlined"
												margin="dense"
												value={queue.greetingMessage}
												multiline
												minRows={2}
												maxRows={10}
												fullWidth
												InputLabelProps={{ shrink: true }}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("queueModal.form.messageAccepting")}
												name="messageAccepting"
												error={touched.messageAccepting && Boolean(errors.messageAccepting)}
												helperText={touched.messageAccepting && errors.messageAccepting}
												variant="outlined"
												margin="dense"
												value={queue.messageAccepting}
												multiline
												minRows={2}
												maxRows={10}
												fullWidth
												InputLabelProps={{ shrink: true }}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
										<Grid item xs={12} sm={12}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("queueModal.form.closedMessage")}
												name="closedMessage"
												error={touched.closedMessage && Boolean(errors.closedMessage)}
												helperText={touched.closedMessage && errors.closedMessage}
												variant="outlined"
												margin="dense"
												value={queue.closedMessage}
												multiline
												minRows={2}
												maxRows={10}
												fullWidth
												InputLabelProps={{ shrink: true }}
												onChange={e => handleChangeValues(e, handleChange)}
											/>
										</Grid>
									</Grid>
								</DialogContent>
							</TabPanel>
							<TabPanel value="2">
								<DialogContent dividers>
									<QueueScheduleComponent
										values={scheduleQueue}
										onChangeValue={setScheduleQueue}
									/>
								</DialogContent>
							</TabPanel>
						</TabContext>
						<DialogActions>
							<Button
								onClick={handleClose}
								color="secondary"
								disabled={isSubmitting}
								variant="outlined"
							>
								{i18n.t("queueModal.buttons.cancel")}
							</Button>
							<Button
								type="submit"
								color="primary"
								disabled={isSubmitting}
								variant="contained"
								className={classes.btnWrapper}
							>
								{queueId
									? `${i18n.t("queueModal.buttons.okEdit")}`
									: `${i18n.t("queueModal.buttons.okAdd")}`}
								{isSubmitting && (
									<CircularProgress
										size={24}
										className={classes.buttonProgress}
									/>
								)}
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default QueueModal;
