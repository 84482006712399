import React, { useContext, useEffect, useReducer, useState } from "react";

import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import { useTheme } from '@mui/material/styles';
import ConfirmationModal from "../../components/ConfirmationModal";
import ScheduleMessageModal from "../../components/ScheduleMessageModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
// routes
import api from "../../services/api";
// hooks
import useSettings from '../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
// sections
import socket from "../../hooks/useSocket";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import { format, parseISO } from "date-fns";
import SelectPropsScheduleMessagesModal from "../../components/SelectPropsScheduleMessagesModal";

const days = [
  { label: "Domingo", key: "sunday" },
  { label: "Segunda", key: "monday" },
  { label: "Terça", key: "tuesday" },
  { label: "Quata", key: "wednesday" },
  { label: "Quinta", key: "thursday" },
  { label: "Sexta", key: "friday" },
  { label: "Sabado", key: "saturday" },
];

const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULE_MESSAGES") {
    const scheduleMessages = action.payload;
    const newScheduleMessages = [];

    scheduleMessages.forEach((quickAnswer) => {
      const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
      if (quickAnswerIndex !== -1) {
        state[quickAnswerIndex] = quickAnswer;
      } else {
        newScheduleMessages.push(quickAnswer);
      }
    });

    return [...state, ...newScheduleMessages];
  }

  if (action.type === "UPDATE_SCHEDULE_MESSAGES") {
    const quickAnswer = action.payload;
    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

    if (quickAnswerIndex !== -1) {
      state[quickAnswerIndex] = quickAnswer;
      return [...state];
    } else {
      return [quickAnswer, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULE_MESSAGES") {
    const scheduleMessagesId = action.payload;

    const quickAnswerIndex = state.findIndex((q) => q.id === scheduleMessagesId);
    if (quickAnswerIndex !== -1) {
      state.splice(quickAnswerIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const ScheduleMessages = () => {
  const { classes } = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [scheduleMessages, dispatch] = useReducer(reducer, []);
  const [selectedScheduleMessages, setSelectedScheduleMessages] = useState(null);
  const [selectedContactModalOpen, setSelectedContactModalOpen] = useState(false);
  const [scheduleMessagesModalOpen, setScheduleMessageModalOpen] = useState(false);
  const [deletingScheduleMessages, setDeletingScheduleMessages] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [whatsappData, setWhatsappData] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { user } = useContext(AuthContext);
  const companyId = user.companyId;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - scheduleMessages.length) : 0;
  const filteredUsers = applySortFilter(scheduleMessages, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);
  // ----------------------------------------------------------------------

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchScheduleMessages = async () => {
        try {
          const { data } = await api.get("/scheduleMessages/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_SCHEDULE_MESSAGES", payload: data.scheduleMessage });
          setHasMore(data?.hasMore || false);
          setLoading(false);
        } catch (err) {
          console.info(err)
          ToastError(err);
        }
      };
      fetchScheduleMessages();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {

    socket.on("scheduleMessages", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SCHEDULE_MESSAGES", payload: data.scheduleMessage });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_SCHEDULE_MESSAGES",
          payload: +data.scheduleMessage,
        });
      }
    });

    return () => {
      socket.off("scheduleMessages");
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenScheduleMessageModal = () => {
    setSelectedScheduleMessages(null);
    setScheduleMessageModalOpen(true);
  };

  const handleCloseScheduleMessageModal = () => {
    setSelectedScheduleMessages(null);
    setScheduleMessageModalOpen(false);
  };

  const handleEditScheduleMessages = (quickAnswer) => {
    setSelectedScheduleMessages(quickAnswer);
    setScheduleMessageModalOpen(true);
  };

  const handleDeleteScheduleMessages = async (scheduleMessagesId) => {
    try {
      await api.delete(`/scheduleMessages/${scheduleMessagesId}`);
      ToastSuccess(i18n.t("scheduleMessages.toasts.deleted"));
    } catch (err) {
      ToastError(err);
    }
    setDeletingScheduleMessages(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const formatDateValues = (sendAt, stopAt, time) => {
    if (stopAt) {
      return `${format(parseISO(sendAt), "dd/MM/yyyy")} até ${format(parseISO(stopAt), "dd/MM/yyyy")} às ${time.split(':').slice(0, 2).join(':')}`;
    } else {
      return `${format(parseISO(sendAt), "dd/MM/yyyy")} às ${time.split(':').slice(0, 2).join(':')}`;
    }
  };

  const formatObjectDays = (sendDays) => {
    const sendDaysFormated = JSON.parse(sendDays);
    return days.map((day) => (
      <Tooltip key={day.key} title={day.label}>
        <div>
          <Checkbox
            disabled
            sx={{ padding: '2px' }}
            icon={<Chip label={day.label.charAt(0)} size="small" variant="outlined" />}
            checkedIcon={<Chip label={day.label.charAt(0)} size="small" color="success" />}
            checked={sendDaysFormated[day.key]}
          />
        </div>
      </Tooltip>
    ));
  };

  const handleSelectPropsScheduleMessages = (selectedData) => {
    const { contact, whatsapp } = selectedData;
    setContactData(contact);
    setWhatsappData(whatsapp);
    setSelectedContactModalOpen(false);
    setTimeout(() => {
      setScheduleMessageModalOpen(true);
    }, 500);
  };

  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <SelectPropsScheduleMessagesModal
          modalOpen={selectedContactModalOpen}
          onClose={(e) => setSelectedContactModalOpen(false)}
          onConfirm={(e) => handleSelectPropsScheduleMessages(e)}
        />
        <ConfirmationModal
          title={
            deletingScheduleMessages &&
            `${i18n.t("scheduleMessages.confirmationModal.deleteTitle")} ${deletingScheduleMessages.shortcut
            }?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteScheduleMessages(deletingScheduleMessages.id)}
        >
          {i18n.t("scheduleMessages.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <ScheduleMessageModal
          contactId={contactData?.id}
          whatsappId={whatsappData?.id}
          open={scheduleMessagesModalOpen}
          onClose={handleCloseScheduleMessageModal}
          scheduleMessageId={selectedScheduleMessages && selectedScheduleMessages.id}
        />
        <Page title={i18n.t("mainDrawer.listItems.schedules")}>
          <Container maxWidth={false}>
            <HeaderBreadcrumbs
              heading={i18n.t("mainDrawer.listItems.schedules")}
              action={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={() => setSelectedContactModalOpen(true)}
                  >
                    {i18n.t("scheduleMessages.buttons.add")}
                  </Button>
                </>
              }
            />
            <Card>
              <TextField
                size="small"
                style={{ padding: '20px' }}
                placeholder={i18n.t("scheduleMessages.searchPlaceholder")}
                type="search"
                value={searchParam}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("scheduleMessages.table.message")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("scheduleMessages.table.contact")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("scheduleMessages.table.recurrence")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("scheduleMessages.table.status")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("scheduleMessages.table.date")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("scheduleMessages.table.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scheduleMessages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                        const { id, message, contact, recurrence, status, sendAt, stopAt, time, sendDays } = row;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell align="center">{message}</TableCell>
                            <TableCell align="center">{contact?.name}</TableCell>
                            <TableCell align="center">{i18n.t(`scheduleMessages.tableData.recurrence.${recurrence}`)}</TableCell>
                            <TableCell align="center">{i18n.t(`scheduleMessages.tableData.status.${status}`)}</TableCell>
                            <TableCell align="center">
                              <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                              }}>
                                <Box>{formatDateValues(sendAt, stopAt, time)}</Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>{!!sendDays && formatObjectDays(sendDays)}</Box>
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => handleEditScheduleMessages(row)}
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setConfirmModalOpen(true);
                                  setDeletingScheduleMessages(row);
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                      {loading && <TableRowSkeleton columns={5} />}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")} ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to} `}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={scheduleMessages.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default ScheduleMessages;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

