import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import { Card, Container } from '@mui/material';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { CalendarToolbar, StyledCalendar } from '../../sections/@dashboard/calendar';

import api from '../../services/api';

import CreateRecurringEvents from '../../helpers/CreateRecurringEvents';

import CalendarEventViewPreview from '../../components/CalendarEventViewPreview';
import CalendarModal from '../../components/CalendarModal';
import useResponsive from '../../hooks/useResponsive';
import socket from '../../hooks/useSocket';
import calendarEventContent from '../../utils/calendarEventContent';


const reducer = (state, action) => {
    switch (action.type) {
        case "LOAD_SCHEDULE_MESSAGES":
            const scheduleMessages = action.payload;
            const newScheduleMessages = [];

            scheduleMessages.forEach((schedule) => {
                const scheduleIndex = state.findIndex((q) => q.id === schedule.id);
                if (scheduleIndex !== -1) {
                    state[scheduleIndex] = schedule;
                } else {
                    newScheduleMessages.push(schedule);
                }
            });

            return [...state, ...newScheduleMessages];

        case "UPDATE_SCHEDULE_MESSAGES":
            const schedule = action.payload;
            const scheduleIndex = state.findIndex((q) => q.id === schedule.id);

            if (scheduleIndex !== -1) {
                state[scheduleIndex] = schedule;
                return [...state];
            } else {
                return [schedule, ...state];
            }

        case "DELETE_SCHEDULE_MESSAGES":
            const scheduleMessagesId = action.payload;
            const deleteIndex = state.findIndex((q) => q.id === scheduleMessagesId);
            if (deleteIndex !== -1) {
                state.splice(deleteIndex, 1);
            }
            return [...state];

        case "RESET":
            return [];

        default:
            return state;
    }
};

const Calendar = () => {
    const [events, dispatch] = useReducer(reducer, []);
    const [recurringEvents, setRecurringEvents] = useState([]);
    const isDesktop = useResponsive('up', 'sm');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [deletingScheduleMessageId, setDeletingScheduleMessageId] = useState(null);
    const [view, setView] = useState(isDesktop ? 'dayGridMonth' : 'listWeek');
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openCalendarModal, setOpenCalendarModal] = useState(false);
    const [openCalendarEventInfo, setOpenCalendarEventInfo] = useState(false);
    const [openCalendarEditModal, setOpenCalendarEditModal] = useState(false);
    const [calendarId, setCalendarId] = useState(null);
    const calendarRef = useRef(null);

    const handleEventClick = (clickInfo) => {
        const calendarItem = clickInfo.event.extendedProps;
        setSelectedEvent(clickInfo.event);
        setCalendarId(calendarItem.id)
        setOpenCalendarEventInfo(true)
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const { data } = await api.get('/calendar')
                if (data.calendars) {
                    dispatch({ type: "LOAD_SCHEDULE_MESSAGES", payload: data.calendars });
                }
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        const calculatedRecurringEvents = events.reduce((acc, item) => {
            const newEvents = CreateRecurringEvents(item, view !== 'dayGridMonth');
            return [...acc, ...newEvents];
        }, []);
        setRecurringEvents(calculatedRecurringEvents);
    }, [events, view]);

    useEffect(() => {

        socket.on("calendar", (data) => {
            if (data.action === "update") {
                dispatch({ type: "UPDATE_SCHEDULE_MESSAGES", payload: data.calendar });
            }
            if (data.action === "create") {
                dispatch({ type: "LOAD_SCHEDULE_MESSAGES", payload: [data.calendar] });
            }
            if (data.action === "delete") {
                dispatch({ type: "DELETE_CONTACT", payload: +data.calendarId });
            }
        });

        return () => {
            socket.off("contact");
        };
    }, []);

    const handleDeleteScheduleMessage = () => {
        dispatch({
            type: "DELETE_SCHEDULE_MESSAGES",
            payload: deletingScheduleMessageId
        });
        setConfirmModalOpen(false);
    };

    const handleResizeEvent = async ({ event }) => {
        dispatch({
            type: "UPDATE_SCHEDULE_MESSAGES",
            payload: {
                id: event.id,
                allDay: event.allDay,
                start: event.start,
                end: event.end,
            },
        });
    };

    const handleDropEvent = async ({ event }) => {
        dispatch({
            type: "UPDATE_SCHEDULE_MESSAGES",
            payload: {
                id: event.id,
                allDay: event.allDay,
                start: event.start,
                end: event.end,
            },
        });
    };

    const handleCreateUpdateEvent = (newEvent) => {
        if (newEvent.id) {
            dispatch({ type: "UPDATE_SCHEDULE_MESSAGES", payload: newEvent });
            // ToastSuccess('Update success!');
        } else {
            dispatch({ type: "LOAD_SCHEDULE_MESSAGES", payload: [newEvent] });
            // ToastSuccess('Create success!');
        }
    };

    const handleDeleteEvent = () => {
        if (selectedEvent) {
            dispatch({ type: "DELETE_SCHEDULE_MESSAGES", payload: selectedEvent.id });
            setSelectedEvent(null);
            // ToastSuccess('Delete success!');
        }
    };

    const handleClickDateNext = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const handleClickDatePrev = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleClickToday = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleChangeView = (newView) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.changeView(newView);
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setView(newView);
            }, 100);
        }
    };

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    return (
        <Container maxWidth={false}>
            <CalendarModal
                open={openCalendarEditModal || openCalendarModal}
                onClose={() => {
                    setOpenCalendarEditModal(false);
                    setOpenCalendarModal(false);
                    setCalendarId(null);
                }}
                calendarId={calendarId}
                onConfirm={console.info}
            />
            <CalendarEventViewPreview
                open={openCalendarEventInfo}
                onClose={() => {
                    setOpenCalendarEventInfo(false);
                    setCalendarId(null);
                }}
                eventId={calendarId}
                onEdit={(id) => {
                    setOpenCalendarEventInfo(false);
                    setCalendarId(id);
                    setOpenCalendarEditModal(true);
                }}
            />
            <Card sx={{ marginTop: '25px' }}>
                <StyledCalendar>
                    <CalendarToolbar
                        date={date}
                        view={view}
                        onNextDate={handleClickDateNext}
                        onPrevDate={handleClickDatePrev}
                        onToday={handleClickToday}
                        onChangeView={handleChangeView}
                        onOpenFilter={handleOpenFilter}
                        setOpenCalendarModal={setOpenCalendarModal}
                    />
                    <FullCalendar
                        weekends
                        editable
                        droppable
                        selectable
                        allDayText={'Dia inteiro'}
                        allDayMaintainDuration
                        eventResizableFromStart
                        eventAllow={(dropInfo, draggedEvent) => {
                            return false;
                            // Se implementarmos a opção de mudar a data apenas arrastando o card, este trecho é importante
                            // if (draggedEvent.extendedProps.type === 'whatsapp') {
                            //     return false;
                            // }
                            // return true;
                        }}
                        moreLinkContent={(moreLinkContentArg) => `+ outras ${moreLinkContentArg?.num || 0} Agendas`}
                        events={recurringEvents}
                        ref={calendarRef}
                        dayMaxEventRows={3}
                        eventDisplay="block"
                        headerToolbar={false}
                        initialDate={date}
                        initialView={view}
                        eventClick={handleEventClick}
                        eventContent={calendarEventContent}
                        selectMirror={true}
                        dayMaxEvents={true}
                        height={isDesktop ? 720 : 'auto'}
                        plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin,]}
                        locale="pt-br"
                    />
                </StyledCalendar>
            </Card>
        </Container>
    );
};

export default Calendar;
