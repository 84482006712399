import React, { useContext, useEffect, useRef, useState } from "react";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import 'dayjs/locale/pt-br';
import { Field, Form, Formik } from "formik";
import { makeStyles } from 'tss-react/mui';
import * as Yup from "yup";

import dayjs from 'dayjs';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ContactsIcon from '@mui/icons-material/Contacts';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { green } from "@mui/material/colors";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import * as XLSX from 'xlsx';
import EmojiPicker from '../../components/EmojiPicker';

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import xlsxFile from "../../assets/xlsx/example.xlsx";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import "./style.css";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexWrap: "wrap",
    },
    textField: {
      marginRight: theme.spacing(1) + 5,
      width: "100%",
    },

    btnWrapper: {
      position: "relative",
    },
    multFieldLine: {
      display: "flex",
      "& > *:not(:last-child)": {
        marginRight: `${theme.spacing(1) + 5}px !important`,
      },
    },
    spacingTime: {
      marginTop: 15,
      width: '50% !important'
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    textQuickAnswerContainer: {
      width: "100%",
      marginTop: 15
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 210
    },
  }
});


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 700,
    },
  },
};



const QuickAnswerSchema = Yup.object().shape({});

const QuickAnswersModal = ({
  open,
  onClose,
  quickAnswerId,
  initialValues,
  onSave,
}) => {

  const { classes } = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const [listingData, setListingData] = useState([]);
  const [media, setMedia] = useState([]);
  const [WhatsappsToSent, setWhatsappsToSent] = useState([]);
  const [whatsapp, setWhatsapp] = useState('');
  const [selected, setSelected] = useState([]);
  const [quickAnswer, setQuickAnswer] = useState('');
  const [shortcut, setShortcut] = useState();
  const [startIn, setStartIn] = useState();
  const [endIn, setEndIn] = useState();
  const [startInterval, setStartInterval] = useState();
  const [endIninterval, setEndInteral] = useState();
  const [inputMessage, setInputMessage] = useState();
  const [sendInterval, setSendInterval] = useState(5);
  const [sendMessageInterval, setSendMessageInterval] = useState(1);
  const [contactsArray, setContactsArray] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);


  const [checked, setChecked] = React.useState(true);

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  function valueTextInterval(value) {
    return `${value}`;
  }
  function valueTextIntervalMessage(value) {
    return `${value}`;
  }

  const handleChangeTextIntervalMessage = (e, value) => {
    if (typeof value === 'number') {
      setSendMessageInterval(value)
    }
  }
  const handleChangeTextInterval = (e, value) => {
    if (typeof value === 'number') {
      setSendInterval(value)
    }
  }

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      try {
        const { data } = await api.get(`/whatsapp/`);
        let connections = [];
        data.forEach(conn => {
          if (conn.channel === 'whatsapp') {
            connections.push(conn)
          }
        });
        setWhatsappsToSent(connections);
      } catch (err) {
        ToastError(err);
      }
    };
    fetchQuickAnswer();
  }, []);

  const handleChangeWhatsapp = (event) => {
    setWhatsapp(event.target.value);
  };

  // config de input de dias // 
  const weekDays = [
    i18n.t("weekDays.sunday"),
    i18n.t("weekDays.monday"),
    i18n.t("weekDays.tuesday"),
    i18n.t("weekDays.wednesday"),
    i18n.t("weekDays.thursday"),
    i18n.t("weekDays.friday"),
    i18n.t("weekDays.saturday"),
  ];


  function renderObjdays() {
    var days = [];
    for (let i = 0; i < selected.length; i++) {
      days.push(weekDays.indexOf(selected[i]))
    }
    return days;
  }

  const isAllSelected =
    weekDays.length > 0 && selected.length === weekDays.length;
  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === weekDays.length ? [] : weekDays);
      return;
    }
    setSelected(value);
  };
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const renderContactsFilter = (e, value) => {
    const filteredData = value.map(item => ({ name: item.name, number: item.number }));
    setSelectedContacts(filteredData);
  }

  const theShortcut = (e) => {
    setShortcut(e.target.value);
  };


  const startInDay = (start) => {
    setStartIn(start);
  };

  const endInDay = (end) => {
    setEndIn(end);
  };

  const startIntervalHours = (start) => {
    setStartInterval(start);
  };

  const endInIntervalHours = (end) => {
    setEndInteral(end);
  };

  function messageInput(e) {
    setInputMessage(e.target.value)
  }
  function setInputValue(value) {
    setInputMessage(inputMessage ? inputMessage + value : value);
  }

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setQuickAnswer((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }
      if (!quickAnswerId) return;
      try {
        const { data } = await api.get(`/massMessaging/${quickAnswerId}`);
        if (isMounted.current) {
          setQuickAnswer(data);
        }
      } catch (err) {
        ToastError(err);
      }
    };

    fetchQuickAnswer();
  }, [quickAnswerId, open, initialValues]);


  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (quickAnswerId) return;
      try {
        const { data } = await api.get(`/listContacts`);
        setContactsArray(data.contacts)
      } catch (err) {
        ToastError(err);
      }
    };
    fetchQuickAnswer();
  }, []);

  const clearState = () => {
    setListingData([]);
    setMedia([]);
    setWhatsappsToSent([]);
    setWhatsapp('');
    setSelected([]);
    setSelectedContacts([]);
    setQuickAnswer('');
    setShortcut('');
    setStartIn('');
    setEndIn('');
    setStartInterval('');
    setEndInteral('');
    setInputMessage('');
  }

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      clearState();
    }, 100);
  };

  function convertSecondsToMilliseconds(seconds) {
    return seconds * 1000;
  }

  const filterContacts = () => {

    if (!contactsInfo?.contacts?.length || contactsInfo?.contacts?.length === 0) {
      return selectedContacts;
    } else {
      const array = contactsInfo.contacts;
      const mergedArr = [...array];
      for (const obj2 of selectedContacts) {
        const duplicateObj = mergedArr.find(obj1 => obj1.number === obj2.number);
        if (!duplicateObj) {
          mergedArr.push(obj2);
        }
      }
      return mergedArr;
    }

  };

  const handleSaveQuickAnswer = async (values) => {

    const contatos = filterContacts();

    const formData = new FormData();
    formData.append("holiday", values.holiday);
    formData.append("companyId", values.companyId);
    formData.append("shortcut", values.shortcut);
    formData.append("message", values.message);
    formData.append("startIn", values.startIn);
    formData.append("endIn", values.endIn);
    formData.append("startInterval", values.startInterval);
    formData.append("endIninterval", values.endIninterval);
    formData.append("period", values.period);
    formData.append("sendToContacts", checked);
    formData.append("weekDays", values.weekDays);
    formData.append("contacts", JSON.stringify(contatos));
    formData.append("interval", convertSecondsToMilliseconds(sendInterval));
    formData.append("messagesPerInterval", sendMessageInterval);
    formData.append("sentBy", values.sentBy);
    formData.append("sendContacts", checked);
    if (values?.mediaName) {
      formData.append("media", media);
      formData.append("mediaName", values.mediaName);
    }
    try {
      if (quickAnswerId) {
        await api.put(`/massMessaging/${quickAnswerId}`, formData);
        handleClose();
        clearState();
      } else {
        const { data } = await api.post("/massMessaging", formData);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      };

      ToastSuccess(i18n.t("quickAnswersModal.success"));

    } catch (err) {
      ToastError(err);
    }
  };

  const clickContacts = e => {
    var uploadContacts = document.querySelector("#inputContacts");
    uploadContacts.click()
  }
  const clickUpload = e => {
    var uploadFile = document.querySelector("#divInputFile > input[type=file]");
    uploadFile.click()
  }

  const [contactsInfo, setContactsInfo] = useState('');

  function formatDate(given_seconds) {
    var hours = Math.floor(given_seconds / 3600);
    var minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
    var seconds = given_seconds - (hours * 3600) - (minutes * 60);
    var timeString = hours.toString().padStart(2, '0') + ':' +
      minutes.toString().padStart(2, '0') + ':' +
      seconds.toString().padStart(2, '0');
    return timeString
  }

  const [holiday, setHoliday] = useState(false);

  function addValuesVariables(value) {
    setInputMessage(inputMessage ? `${inputMessage.trim()} ${value}` : value);
  }


  const setHolidayTag = e => {
    setHoliday(e.target.value);
  }

  const contactsUploaded = file => {
    var success = [];
    var error = [];
    var duplicated = [];
    try {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (e) => {
        const bufferArray = e?.target.result
        const wb = XLSX.read(bufferArray, { type: "buffer" })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws)
        for (let i = 0; i < data.length; i++) {
          var name_ = data[i]?.name ? data[i]?.name : 'not_name';
          var number_ = data[i].number;
          if (name_ === '' || number_ === '' || String(number_).length < 12 || String(number_).length > 13 || (success.findIndex(i => i.number === number_) != -1)) {
            if ((success.findIndex(i => i.number === number_) != -1)) {
              duplicated.push({ 'name': name_, 'number': number_ })
            } else {
              error.push({ 'name': name_, 'number': number_ })
            }
          } else {
            success.push({ 'name': name_, 'number': number_ })
          }
        }

        setContactsInfo({
          "valid": success.length,
          "error": error.length,
          "duplicated": duplicated.length,
          "estimatedTime": formatDate(success.length * 15),
          "contacts": success
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  function padTo2Digits(num) {
    return String(num).padStart(2, '0');
  }

  function convertSecondsToMinutes(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    return `${padTo2Digits(minutes)}:${padTo2Digits(remainingSeconds)}`;
  }

  const verifyInterval = (start, end) => {
    const date1 = new Date(`2018-04-07 ${start}`);
    const date2 = new Date(`2018-04-07 ${end}`);
    if (date1.getTime() === date2.getTime()) {
      console.error('Os horarios não podem ser iguals');
      return
    }
    else if (date1.getTime() > date2.getTime()) {
      return 'nocturnal';
    }
    else {
      return 'daytime';
    }
  }

  const formatDateSent = (date) => {
    var data = new Date(date);
    return data;
  }

  const renderInBase64 = (file) => {
    setMedia(file)
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setListingData({ ...listingData, selectedFile: { name: file.name, type: file.type, size: '', base64: reader.result } })
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }


  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="body"
        BackdropProps={{
          style: {
            backdropFilter: 'blur(3px)',
            backgroundColor: 'rgba(0, 0, 30, 0.4)',
          },
        }}
      >
        <DialogTitle id="form-dialog-title" style={{
          display: 'flex', justifyContent: 'space-between'
        }}>
          <p> {quickAnswerId
            ? `${i18n.t("massMessaging.edit")}`
            : `${i18n.t("massMessaging.add")}`}
          </p>
          <Button variant="contained" href={xlsxFile} download="example.xlsx" color="success">
            <FileOpenIcon /> &nbsp; {i18n.t("massMessaging.buttons.example")}
          </Button>
        </DialogTitle>
        <Formik
          initialValues={quickAnswer}
          enableReinitialize={true}
          validationSchema={QuickAnswerSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              let content = {
                "holiday": holiday,
                "companyId": user.companyId,
                "shortcut": shortcut,
                "message": inputMessage,
                "startIn": formatDateSent(startIn),
                "endIn": formatDateSent(endIn),
                "startInterval": formatDateSent(startInterval),
                "endIninterval": formatDateSent(endIninterval),
                "period": verifyInterval(startInterval, endIninterval),
                "weekDays": JSON.stringify(renderObjdays()),
                "contacts": JSON.stringify(contactsInfo.contacts),
                "sentBy": String(whatsapp),
              }

              if (listingData.selectedFile) {
                content = {
                  ...content,
                  "media": listingData?.selectedFile?.base64,
                  "mediaName": listingData?.selectedFile?.name,
                }
              }

              if (!listingData.selectedFile && !inputMessage) {
                ToastError('Selecione uma mensagem ou um arquivo para continuar.', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                })
              } else {
                handleSaveQuickAnswer(content);
              }
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <Field
                style={{ display: "none" }}
                as={TextField}
                type="hidden"
                name="companyId"
              />
              <DialogContent dividers style={{ maxHeight: '65vh' }}>
                {!quickAnswerId && <>
                  <div className={classes.textQuickAnswerContainer} style={{ flexDirection: 'column' }}>
                    <FormControl style={{ width: '50%', padding: 5 }}>
                      <Field
                        as={TextField}
                        label={i18n.t("massMessaging.table.shortcut")}
                        name="shortcut"
                        autoFocus
                        autoComplete='off'
                        error={touched.shortcut && Boolean(errors.shortcut)}
                        helperText={touched.shortcut && errors.shortcut}
                        variant="outlined"
                        margin="dense"
                        value={shortcut}
                        onChange={theShortcut}
                        className={classes.textField}
                        fullWidth
                      />
                    </FormControl>
                    <FormControl style={{ width: '50%', padding: 5, marginTop: '8px' }}>
                      <InputLabel style={{ margin: '5px 0px 0px 5px' }} id="demo-simple-select-label">{i18n.t("massMessagings.wpp.name")}</InputLabel>
                      <Select
                        style={{ display: 'flex' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={whatsapp}
                        label={i18n.t("massMessagings.wpp.name")}
                        onChange={handleChangeWhatsapp}
                      >
                        {WhatsappsToSent.map(function (wpp) {
                          return <MenuItem
                            disabled={wpp.status !== 'CONNECTED'}
                            value={wpp.number}>
                            <WhatsAppIcon
                              style={{
                                color: wpp.status === 'CONNECTED' ?
                                  '#36b22f' :
                                  '#f03333'
                              }}
                            />
                            <p
                              style={{ marginLeft: 10 }}>
                              {wpp.status === 'CONNECTED' ?
                                `${wpp.name} - ${wpp.number} - ${i18n.t("massMessagings.wpp.connected")}` :
                                `${wpp.name} - ${i18n.t("massMessagings.wpp.disconnect")}`}
                            </p>
                          </MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.multFieldLine}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                      <Stack spacing={3} className={classes.spacingTime} style={{ padding: 5 }}>
                        <DesktopDatePicker
                          className={classes.textField}
                          label={i18n.t("massMessaging.actions.startIn")}
                          value={dayjs(startIn)}
                          name="startIn"
                          inputFormat="DD/MM/YYYY"
                          minDate={dayjs(new Date())}
                          disabled={shortcut ? false : true}
                          onChange={startInDay}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </Stack>
                      <Stack spacing={3} className={classes.spacingTime} style={{ padding: 5 }}>
                        <DesktopDatePicker
                          className={classes.textField}
                          label={i18n.t("massMessaging.actions.endIn")}
                          value={dayjs(endIn)}
                          name="endIn"
                          inputFormat="DD/MM/YYYY"
                          minDate={dayjs(startIn)}
                          disabled={startIn ? false : true}
                          onChange={endInDay}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                  <div className={classes.multFieldLine}>
                    <FormControl style={{ width: '50%', padding: '15px 25px' }}>
                      <Typography id="input-slider" gutterBottom>
                        {i18n.t("massMessaging.table.interval")}
                      </Typography>
                      <Box>
                        <Slider
                          value={sendInterval}
                          onChange={handleChangeTextInterval}
                          getAriaValueText={valueTextInterval}
                          valueLabelDisplay="auto"
                          disabled={endIn ? false : true}
                          step={1}
                          marks
                          min={1}
                          max={60}
                        />
                      </Box>
                      <Typography id="input-slider" gutterBottom>
                        {`Atual:  ${convertSecondsToMinutes(sendInterval)}`}
                      </Typography>
                    </FormControl>
                    <FormControl style={{ width: '50%', padding: '15px 25px' }}>
                      <Typography id="input-slider" gutterBottom>
                        {i18n.t("massMessaging.table.intervalMessages")}
                      </Typography>
                      <Box>
                        <Slider
                          value={sendMessageInterval}
                          onChange={handleChangeTextIntervalMessage}
                          getAriaValueText={valueTextIntervalMessage}
                          valueLabelDisplay="auto"
                          disabled={endIn ? false : true}
                          step={1}
                          marks
                          min={1}
                          max={10}
                        />
                      </Box>
                      <Typography id="input-slider" gutterBottom>
                        {`Atual:  ${sendMessageInterval}`}
                      </Typography>
                    </FormControl>
                  </div>
                  <div className={classes.multFieldLine}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                      <Stack spacing={3} className={classes.spacingTime} style={{ padding: 5 }}>
                        <MobileTimePicker
                          clearable
                          ampm={false}
                          className={classes.textField}
                          label={i18n.t("massMessaging.actions.intervalStart")}
                          value={startInterval}
                          format="HH:mm:ss"
                          name="startInterval"
                          disabled={endIn ? false : true}
                          onChange={startIntervalHours}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </Stack>
                      <Stack spacing={3} className={classes.spacingTime} style={{ padding: 5 }}>
                        <MobileTimePicker
                          clearable
                          ampm={false}
                          className={classes.textField}
                          label={i18n.t("massMessaging.actions.intervalEnd")}
                          value={endIninterval}
                          format="HH:mm:ss"
                          name="endIninterval"
                          disabled={startInterval ? false : true}
                          onChange={endInIntervalHours}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                  <div className={classes.textQuickAnswerContainer} style={{ padding: 5 }}>
                    <FormControlLabel control={<Checkbox
                      checked={checked}
                      onChange={handleChangeChecked}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Enviar para contatos da agenda" />
                    {!checked && <Autocomplete
                      disabled={endIninterval ? false : true}
                      multiple
                      onChange={(e, value) => renderContactsFilter(e, value)}
                      limitTags={5}
                      options={contactsArray}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField {...params} label="Contatos" placeholder="Contatos" />
                      )}
                    />}
                  </div>
                  <div className={classes.textQuickAnswerContainer} style={{ display: 'flex' }}>
                    <div style={{ width: '50%', padding: 5 }}>
                      <input type="file"
                        id="inputContacts"
                        style={{ display: 'none' }} onChange={e => {
                          const file = e.target.files[0]
                          contactsUploaded(file)
                        }}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                      <Card>
                        <CardActionArea disabled={endIninterval ? false : true} onClick={e => { clickContacts() }}>
                          <CardContent >
                            <Typography style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }} gutterBottom variant="p" component="div">
                              <PermContactCalendarIcon /> &nbsp; {i18n.t("massMessaging.contacts")}
                            </Typography>
                            {contactsInfo &&
                              <>
                                <Typography dividers variant="body2" color="text.secondary">
                                  <List sx={{ bgcolor: 'background.paper' }}>
                                    <div style={{ display: 'flex' }}>
                                      <ListItem>
                                        <ListItemAvatar>
                                          <TaskAltIcon style={{ color: 'green' }} />
                                        </ListItemAvatar>
                                        <p>{`${i18n.t("massMessaging.success")}${contactsInfo.valid}`}</p>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar>
                                          <ErrorOutlineIcon style={{ color: 'red' }} />
                                        </ListItemAvatar>
                                        <p>{`${i18n.t("massMessaging.error")}${contactsInfo.error}`}</p>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemAvatar>
                                          <ContactsIcon />
                                        </ListItemAvatar>
                                        <p>{`${i18n.t("massMessaging.duplicated")}${contactsInfo.duplicated}`}</p>
                                      </ListItem>
                                    </div>
                                  </List>
                                </Typography>
                              </>
                            }
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </div>
                    <div style={{ width: '50%', padding: 5 }}>
                      <Card>
                        <CardActionArea disabled={checked || contactsInfo || selectedContacts.length > 0 ? false : true} onClick={e => { clickUpload() }}>
                          <CardContent>
                            <Typography style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }} gutterBottom variant="p" component="div">
                              <CloudUploadIcon /> &nbsp; {i18n.t("massMessaging.file")}
                            </Typography>
                            {listingData?.selectedFile &&
                              <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Typography variant="body2" color="text.secondary">
                                    {(listingData.selectedFile.type).includes('image') &&
                                      <>
                                        <div style={{ padding: '5px' }}>
                                          <CardMedia
                                            component="img"
                                            height="140"
                                            image={listingData.selectedFile.base64}
                                            alt="uploaded by user"
                                          />
                                        </div>
                                      </>}
                                    <Chip
                                      label={listingData.selectedFile.name}
                                      onDelete={e => { setListingData(''); setMedia([]); }}
                                    />
                                  </Typography>
                                </div>
                              </>
                            }
                          </CardContent>
                        </CardActionArea>
                      </Card>
                      <div id="divInputFile" style={{ display: 'none' }}>
                        <input
                          type="file"
                          accetp="image/*,application/pdf,audio/*"
                          id="inputFile"
                          multiple={false}
                          onChange={(e) => { renderInBase64(e.target.files[0]) }} />
                      </div>
                    </div>
                  </div>
                </>
                }

                <div className={classes.textQuickAnswerContainer} style={{ display: 'flex' }}>
                  <EmojiPicker hidden={(checked || contactsInfo || selectedContacts.length > 0) ? false : true} style={{ marginTop: '2px', zIndex: 99, padding: '12px' }} value={''} setValue={setInputValue} />
                  <Field
                    as={TextField}
                    label={i18n.t("massMessaging.table.message")}
                    name="message"
                    id="message"
                    value={inputMessage}
                    autoComplete='off'
                    onChange={messageInput}
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    disabled={checked || contactsInfo || selectedContacts.length > 0 ? false : true}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    style={{ width: contactsInfo ? '90%' : '100%', margin: 0 }}
                    multiline
                    fullWidth
                  />
                </div>
                {(checked || contactsInfo || selectedContacts.length > 0) && <>
                  <div className={classes.textQuickAnswerContainer} style={{ display: 'flex' }}>
                    <Button style={{ margin: 1 }} onClick={e => addValuesVariables('{{name_contact}}')} variant="outlined">Nome do contato</Button>
                    <Button style={{ margin: 1 }} onClick={e => addValuesVariables('{{date_send}}')} variant="outlined">Data de envio</Button>
                    <Button style={{ margin: 1 }} onClick={e => addValuesVariables('{{condolences}}')} variant="outlined">Bom dia/ Boa tarde/ Boa noite</Button>
                  </div>
                </>
                }
                {!quickAnswerId && <FormControl sx={{ marginTop: '15px', width: `100%` }}>
                  <InputLabel id="mutiple-select-label"> {i18n.t("weekDays.title")}</InputLabel>
                  <Field
                    as={Select}
                    labelId="mutiple-select-label"
                    multiple
                    value={selected}
                    disabled={inputMessage || listingData?.selectedFile?.name ? false : true}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip style={{ height: '25px', borderRadius: '5px' }} key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllSelected}
                          indeterminate={
                            selected.length > 0 && selected.length < weekDays.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.t("massMessaging.selectAll")}
                      />
                    </MenuItem>
                    {weekDays.map((day) => (
                      <MenuItem key={day} value={day}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(day) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={day} />
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>}
              </DialogContent >
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {quickAnswerId
                    ? `${i18n.t("quickAnswersModal.buttons.okEdit")}`
                    : `${i18n.t("quickAnswersModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form >
          )}
        </Formik >
      </Dialog >
    </div >
  );
};

export default QuickAnswersModal;
