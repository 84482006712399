// @mui
import { Grid, IconButton, Stack, Popover, Typography, Tooltip } from '@mui/material';
// hooks
import { useState } from 'react';
import Iconify from '../../components/Iconify';
import { i18n } from '../../translate/i18n';
import SettingColorPresets from '../../components/settings/SettingColorPresets';

// ----------------------------------------------------------------------

export default function ChangeColor() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Stack direction="row" alignItems="center">
            <Grid dir="ltr" container>
                <Tooltip title={i18n.t("userType.translate.colors")}>
                    <IconButton
                        sx={{ padding: '5px' }}
                        aria-label="change color"
                        size="large"
                        onClick={handleClick} // Abre o popover ao clicar
                    >
                        <Iconify icon={'ph:paint-bucket-duotone'} width={28} height={28} />
                    </IconButton>
                </Tooltip>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Stack sx={{ p: 2 }}>
                        <Typography variant="subtitle2">{i18n.t("userType.translate.colors")}</Typography>
                        <SettingColorPresets /> {/* Componente para seleção de cores */}
                    </Stack>
                </Popover>
            </Grid>
        </Stack>
    );
}
