import { Howl } from "howler";

// Criar uma instância de Howl para o som desejado
const notificationSound = new Howl({
    src: [require("../assets/alarm-event-sound.mp3")], // Altere para o seu arquivo de áudio
    html5: true // Isso permite que o som seja carregado como um streaming
});

let currentSound = null;
let lastCallTime = 0;

// Função para tocar o som de notificação
const playNotificationEventSound = () => {
    const localStorageSound = localStorage.getItem("volume") ? Number(localStorage.getItem("volume")) : 50;
    const adjustedVolume = Math.max(0, Math.min(100, localStorageSound)) / 100;
    notificationSound.volume(adjustedVolume);

    const currentTime = Date.now();

    // Verifica se já se passaram pelo menos 500ms desde a última chamada
    if (currentTime - lastCallTime >= 500) {
        // Para o som atual, se existir
        if (currentSound && typeof currentSound.stop === "function") {
            currentSound.stop();
        }

        // Reproduz o novo áudio
        currentSound = notificationSound.play();

        // Atualiza o tempo da última chamada
        lastCallTime = currentTime;
    } else {
        // console.info("Notification ignored: too soon.");
    }
};

export { playNotificationEventSound };
