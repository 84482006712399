import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";


import { AuthContext } from "../context/Auth/AuthContext";


// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../hooks/useCollapseDrawer';
import useResponsive from '../hooks/useResponsive';
import useSettings from '../hooks/useSettings';
// config
import { HEADER, NAVBAR } from '../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';


import KeepSocketAlive from "../hooks/KeepSocketAlive";
import socket from "../hooks/useSocket";

// ----------------------------------------------------------------------
import CalendarEventNotification from "../components/CalendarEventNotification";
import MultiTabDetector from "../helpers/MultiTabDetector";
import api from "../services/api";

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 34,
  [theme.breakpoints.up('lg')]: {
    //paddingLeft: 16,
    // paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT - 35,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT - 35,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

const drawerWidth = 240;

const LoggedInLayout = ({ children }) => {
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { handleLogoutDisconnect, loading, user, handleStatus } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { themeLayout } = useSettings();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  let verticalLayout = themeLayout === 'vertical';
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [tab, setTab] = useState(false);
  const statusRef = useRef(0);
  const activityTimeout = useRef(null);

  const [eventData, setEventData] = useState(null);
  const [eventModalOpen, setEventModalOpen] = useState(true);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  useEffect(() => {
    const detector = new MultiTabDetector();
    detector.isMultipleTabs((isMultiple) => {
      if (isMultiple) {
        history.push('/session/error')
      }
    });
  }, []);

  const getEventData = async (eventId) => {

    try {
      const { data } = await api.get(`/calendar/${eventId}`)
      setEventData(data)
      setTimeout(() => { setEventModalOpen(true) }, 500);
    } catch (error) {
      console.error(error)
    }

  };
  useEffect(() => {

    if (!user?.companyId) return;

    socket.connect(user.companyId);

    const handleActivity = () => {
      if (activityTimeout.current) clearTimeout(activityTimeout.current);

      if (statusRef.current !== 1) {
        socket.emit('change_status', { userId: user.id, status: 1 });
        statusRef.current = 1;
      }

      activityTimeout.current = setTimeout(() => {
        if (statusRef.current !== 2) {
          socket.emit('change_status', { userId: user.id, status: 2 });
          statusRef.current = 2;
        }
      }, 5 * 60 * 1000); // Usuário fica ausente após 5 minutos de inatividade
    };


    socket.on('connect', () => {
      socket.emit('register', { userId: user.id });
      handleActivity();
    });


    socket.on('disconnect', () => {
      if (activityTimeout.current) clearTimeout(activityTimeout.current);
      statusRef.current = 0;
    });


    socket.on(`user_status_${user.companyId}`, (data) => {
      console.info(`Usuário: [${data.id}]:[${data.name}] está ${data.status === 0 ? 'OFFLINE' : data.status === 1 ? 'ONLINE' : 'AUSENTE'}`);
      if (data.id === user.id) {
        handleStatus(data.status)
      }
    });


    socket.on(`calendar`, (data) => {
      const { action, calendarId, users } = data;
      if (action === "notification") {
        if (users.includes(user.id)) {
          getEventData(calendarId);
        }
        // ToastCustom(event.title, event.description);
      }
    });

    // socket.on(`find-${user.id}-user`, ({ uuid }) => {
    //   const deviceUUID = UserSession.getUUID();
    //   if (uuid != deviceUUID) {
    //     handleLogoutDisconnect();
    //   }
    // })

    const handleMouseMove = () => handleActivity();
    const handleKeyDown = () => handleActivity();
    const handleBeforeUnload = () => socket.emit('change_status', { userId: user.id, status: 1 });

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // socket.disconnect();
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [user]);


  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(true);
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {user?.companyId && user?.id && <KeepSocketAlive
        companyId={user.companyId}
        userId={user.id}
      />}
      <div className="pageMenu">
        <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
        <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      </div>
      <MainStyle collapseClick={collapseClick} id="layout">
        {children ? children : null}
      </MainStyle>
      <CalendarEventNotification
        event={eventData}
        open={eventModalOpen}
        onClose={setEventModalOpen}
        onConfirm={console.info}
      />
    </Box>
  );
}

export default LoggedInLayout;