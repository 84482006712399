import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Drawer,
    FormControlLabel,
    IconButton,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import KanbanPriorityAutoComplete from '../KanbanPriorityAutoComplete';
import KanbanPriorityModal from '../KanbanPriorityModal';
import KanbanUsersAutoComplete from '../KanbanUsersAutoComplete';

import IdentifyGender from '../../helpers/IdentifyGender';

const KanbanFiltersDrawer = ({ open, onClose, kanban, priorities, loadingPriority, taskTitle, onFilter }) => {

    // MODAL
    const [openPriorityModal, setOpenPriorityModal] = useState(false);

    // Kanban props
    const [assigneds, setAssigneds] = useState([]);

    // Filtros
    const [showTaskCount, setShowTaskCount] = useState(false);
    const [showTaskNumber, setShowTaskNumber] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [priorityFilter, setPriorityFilter] = useState(null);
    const [assignedFilter, setAssignedFilter] = useState([]);
    const [createdByFilter, setCreatedByFilter] = useState([]);

    // Loadings
    const [loadingAssigneds, setLoadingAssigneds] = useState(true);

    useEffect(() => {
        setLoadingAssigneds(true);
        setAssigneds(kanban?.assigneds || []);
        setLoadingAssigneds(false);
    }, [kanban])


    const resetFilters = () => {
        setPriorityFilter(null);
        setAssignedFilter([]);
        setCreatedByFilter([])
        setShowTaskCount(true);
        setShowTaskNumber(false);
        setShowTags(false);
    };

    const handleClose = () => {
        onClose(false);
    };

    const updatePriorityValue = (values, setFunction) => {
        if (!values || values.length === 0 || (values.length === 1 && values[0] === undefined)) {
            setFunction([]);
        } else {
            setFunction([values[values.length - 1]]);
        }
    };

    const handleCreatePriority = () => {
        setOpenPriorityModal(true)
    };

    const applyFilters = () => {

        const filters = {
            showTaskCount,
            showTaskNumber,
            showTags,
            priorityFilter,
            assignedFilter,
            createdByFilter,
        };

        localStorage.setItem('kanbanFilters', JSON.stringify(filters));
        onFilter(filters);
        handleClose();
    };

    useEffect(() => {
        if (!open) return;
        const savedFilters = localStorage.getItem('kanbanFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            setShowTaskCount(parsedFilters.showTaskCount);
            setShowTaskNumber(parsedFilters.showTaskNumber);
            setShowTags(parsedFilters.showTags);
            setPriorityFilter(parsedFilters.priorityFilter);
            setAssignedFilter(parsedFilters.assignedFilter);
            setCreatedByFilter(parsedFilters.createdByFilter);
        }
    }, [open]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => handleClose()}
            PaperProps={{
                sx: {
                    width: 500,
                    p: 0,
                    //   backgroundColor: '#ffffff00',
                    backdropFilter: 'blur(20px)',
                },
            }}>
            <Box sx={{ width: '100%', padding: 3 }}>
                {/* Cabeçalho */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <FilterListIcon
                            color="primary"
                            sx={{ marginRight: '5px' }}
                        />
                        <Typography
                            variant="h6"
                            color="primary"
                        >
                            Filtros Kanban
                        </Typography>
                    </Box>
                    <IconButton onClose={() => handleClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Divider sx={{ mb: 2 }} />

                <Typography variant="subtitle1" gutterBottom>
                    Configurações de Exibição
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showTaskCount}
                            onChange={(e) => setShowTaskCount(e.target.checked)}
                        />
                    }
                    label="Exibir Contagem de Tarefas"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showTags}
                            onChange={(e) => setShowTags(e.target.checked)}
                        />
                    }
                    label="Exibir Marcadores"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showTaskNumber}
                            onChange={(e) => setShowTaskNumber(e.target.checked)}
                        />
                    }
                    label={`Exibir Número ${(IdentifyGender(taskTitle) == 'm' || IdentifyGender(taskTitle) == 'n') ? 'do' : 'da'} ${taskTitle.charAt(0).toUpperCase() + taskTitle.slice(1)}`}
                />

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle1" gutterBottom>
                    Filtros de Tarefas
                </Typography>


                <Box mt={2}>
                    <KanbanUsersAutoComplete
                        labels={assigneds || []}
                        placeholder="Por autoria"
                        search={"Filtrar autorias"}
                        setValue={setCreatedByFilter}
                        value={createdByFilter || []}
                        loading={loadingAssigneds}
                    />
                </Box>

                <Box mt={2}>
                    <KanbanPriorityAutoComplete
                        labels={priorities || []}
                        loading={loadingPriority}
                        placeholder="Por prioridade"
                        search={"Filtrar por prioridade"}
                        setValue={(values) => updatePriorityValue(values, setPriorityFilter)}
                        value={priorityFilter}
                        handleCreate={handleCreatePriority}
                    />
                </Box>

                <Box mt={2}>
                    <KanbanUsersAutoComplete
                        labels={assigneds || []}
                        placeholder="Por atribuidos"
                        search={"Filtrar atribuidos"}
                        setValue={setAssignedFilter}
                        value={assignedFilter || []}
                        loading={loadingAssigneds}
                    />
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: 2,
                        zIndex: 1201
                    }}
                    display="flex"
                    justifyContent="space-between"
                >
                    <Button variant="outlined" onClick={resetFilters}>
                        Limpar filtros
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => applyFilters()}>
                        Aplicar
                    </Button>
                </Box>

            </Box>
            <KanbanPriorityModal
                open={openPriorityModal}
                onClose={setOpenPriorityModal}
                kanbanId={kanban?.id}
            />
        </Drawer >
    );
};

export default KanbanFiltersDrawer;
