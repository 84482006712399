import PropTypes from 'prop-types';
// @mui
import { Stack, Button, Box, Tooltip, Typography, IconButton, ToggleButton } from '@mui/material';
// utils
import { fDate, fDate2, fDate3, fDay } from '../../../utils/formatTime';
import { addDays, format, startOfDay } from 'date-fns'; // Importando funções adicionais do date-fns
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
import { i18n } from '../../../translate/i18n';

// ----------------------------------------------------------------------

const VIEW_OPTIONS = [
  { value: 'dayGridMonth', label: 'Mês', icon: 'ic:outline-calendar-month' },
  { value: 'timeGridWeek', label: 'Semana', icon: 'ic:round-view-week' },
  { value: 'timeGridDay', label: 'Dia', icon: 'meteocons:dust-day-fill' },
  { value: 'listWeek', label: 'Lista', icon: 'ic:twotone-filter-list' },
];

// ----------------------------------------------------------------------

CalendarToolbar.propTypes = {
  onToday: PropTypes.func,
  onNextDate: PropTypes.func,
  onPrevDate: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onChangeView: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  view: PropTypes.oneOf(['dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek']),
};

export default function CalendarToolbar({
  date,
  view,
  onToday,
  onNextDate,
  onPrevDate,
  onChangeView,
  onOpenFilter,
  setOpenCalendarModal,
}) {
  const isDesktop = useResponsive('up', 'sm');

  // Função para obter a data atual, a data de entrada e a data que está 5 dias à frente
  const getDateDetails = (inputDate) => {
    const currentDate = startOfDay(new Date()); // Obtém a data atual sem a hora
    const inputDateObj = startOfDay(inputDate); // Garante que a data de entrada também não tenha hora
    const datePlusFiveDays = addDays(inputDateObj, 6); // Calcula a data que está 5 dias à frente

    return {
      inputDate: format(inputDateObj, 'yyyy-MM-dd'), // Formata a data de entrada
      currentDate: format(currentDate, 'yyyy-MM-dd'), // Formata a data atual
      datePlusFiveDays: format(datePlusFiveDays, 'yyyy-MM-dd'), // Formata a data que está 5 dias à frente
    };
  };

  // Chama a função getDateDetails com a data recebida como prop
  const dateInfo = getDateDetails(date);
  console.info(dateInfo);
  console.info({ view });
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ p: 1.5 }}
    >
      {isDesktop && (
        <Stack direction="row" spacing={1}>
          {VIEW_OPTIONS.map((viewOption) => (
            <ToggleButton
              size="small"
              value={view}
              selected={viewOption.value === view}
              onChange={() => onChangeView(viewOption.value)}
            >
              {viewOption.label}
            </ToggleButton>
          ))}
        </Stack>
      )}

      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton onClick={onPrevDate}>
          <Iconify icon="eva:arrow-ios-back-fill" />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            width: '200px',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography variant="h5">{view === 'timeGridDay' ? fDate(date) : fDate2(date)}</Typography>
          {(view === "listWeek" || view === 'timeGridWeek') && <Typography variant="body1" sx={{ fontSize: '15px' }}> {fDate3(dateInfo.inputDate)} Até {fDate3(dateInfo.datePlusFiveDays)}</Typography>}
        </Box>

        <IconButton onClick={onNextDate}>
          <Iconify icon="eva:arrow-ios-forward-fill" />
        </IconButton>
      </Stack>

      {/* Exibe as datas abaixo do Typography existente */}
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setOpenCalendarModal(true)}
        >
          Novo Evento de Agenda
        </Button>
        {isDesktop && (
          <Button size="small" color="error" variant="outlined" onClick={onToday}>
            Hoje
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
