import React, { useContext, useEffect, useReducer, useState } from "react";

import {
  Button,
  Card,
  Container,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { format, parseISO } from 'date-fns';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AutofpsSelectIcon from '@mui/icons-material/AutofpsSelect';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import ChatbotDiagramModal from "../../components/ChatbotDiagramModal";
import ChatbotModal from "../../components/ChatbotModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import socket from "../../hooks/useSocket";


const reducer = (state, action) => {
  if (action.type === "LOAD_QUICK_ANSWERS") {
    const chatbot = action.payload;
    const newChatbot = [];

    chatbot.forEach((chatbot) => {
      const chatbotIndex = state.findIndex((q) => q.id === chatbot.id);
      if (chatbotIndex !== -1) {
        state[chatbotIndex] = chatbot;
      } else {
        newChatbot.push(chatbot);
      }
    });

    return [...state, ...newChatbot];
  }

  if (action.type === "UPDATE_QUICK_ANSWERS") {

    const chatbot = action.payload;
    const chatbotIndex = state.findIndex((q) => q.id === chatbot.id);
    if (chatbotIndex !== -1) {
      state[chatbotIndex] = chatbot;
      return [...state];
    } else {
      return [chatbot, ...state];
    }
  }

  if (action.type === "DELETE_QUICK_ANSWERS") {
    const chatbotId = action.payload;

    const chatbotIndex = state.findIndex((q) => q.id === chatbotId);
    if (chatbotIndex !== -1) {
      state.splice(chatbotIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});


function downloadObjectAsJson(row) {
  const { name } = row
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(row));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", name + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

const chatbot = () => {

  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [chatbot, dispatch] = useReducer(reducer, []);
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [ChatbotModalOpen, setChatbotModalOpen] = useState(false);
  const [deletingChatbot, setDeletingChatbot] = useState(null);
  const [editFlowChatbot, setEditFlowChatbot] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [chatbotDiagramModalOpen, setChatbotDiagramModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({})
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { user } = useContext(AuthContext);

  // ----------------------------------------------------------------------

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - chatbot.length) : 0;

  const filteredUsers = applySortFilter(chatbot, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);
  // ----------------------------------------------------------------------



  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchChatbot = async () => {
        try {
          const { data } = await api.get("/chatbot/", {
            params: { searchParam, pageNumber },
          });

          var filteredChatbots = [];
          data.forEach(chatbot => {
            if (chatbot.companyId == user.companyId) {
              filteredChatbots.push(chatbot)
            }
          });

          dispatch({ type: "LOAD_QUICK_ANSWERS", payload: filteredChatbots });
          setLoading(false);
        } catch (err) {
          console.error(err)
          ToastError(err);
        }
      };
      fetchChatbot();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {

    socket.on("chatbot", (data) => {
      if (data.action === "update" || data.action === "create") {
        if (!(+user.companyId === +data.chatbot.companyId)) return;
        dispatch({ type: "UPDATE_QUICK_ANSWERS", payload: data.chatbot });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_QUICK_ANSWERS",
          payload: +data.chatbotId,
        });
      }
    });

    return () => {
      socket.off("chatbot");
    };
  }, []);


  const getParamsJson = event => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(event.target.files[0]);
  }


  const onReaderLoad = async (event) => {

    const obj = JSON.parse(event.target.result);
    const { name, edges, nodes } = obj
    const nameFlow = `${name}_imported_${new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })}`

    const values = {
      name: nameFlow,
      edges: JSON.parse(edges),
      nodes: JSON.parse(nodes)
    }

    document.getElementById('inputUploadFlow').value = null;

    try {
      await api.post("/chatbot-import", values);
      ToastSuccess(i18n.t("ChatbotModal.success"));
    } catch (err) {
      ToastError(err);
    }

  }

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenChatbotModal = () => {
    setSelectedChatbot(null);
    setChatbotModalOpen(true);
  };

  const handleCloseChatbotModal = () => {
    setSelectedChatbot(null);
    setChatbotModalOpen(false);
  };

  const handleEditChatbot = (chatbot) => {
    setSelectedChatbot(chatbot);
    setChatbotModalOpen(true);
  };

  const handleDeleteChatbot = async (chatbotId) => {
    try {
      await api.delete(`/chatbot/${chatbotId}`);
      ToastSuccess(i18n.t("chatbot.toasts.deleted"));
    } catch (err) {
      ToastError(err);
    }
    setEditFlowChatbot(null)
    setDeletingChatbot(null);
    setSearchParam("");
    setPageNumber(1);
  };

  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            deletingChatbot &&
            `${i18n.t("chatbot.confirmationModal.deleteTitle")} ${deletingChatbot.name
            }?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteChatbot(deletingChatbot.id)}
        >
          {i18n.t("chatbot.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <ChatbotDiagramModal
          chatbot={editFlowChatbot}
          open={chatbotDiagramModalOpen}
          onClose={setChatbotDiagramModalOpen}
        />
        <ChatbotModal
          open={ChatbotModalOpen}
          onClose={handleCloseChatbotModal}
          aria-labelledby="form-dialog-title"
          chatbotId={selectedChatbot && selectedChatbot.id}
        />
        <Page title="Chatbot">
          <Container maxWidth={false}>
            <HeaderBreadcrumbs
              heading="Chatbot"
              action={
                <>
                  <Button
                    style={{ margin: 5 }}
                    variant="outlined"
                    startIcon={<DataObjectIcon />}
                    component="label">
                    Importar de arquivo
                    <input id="inputUploadFlow" onChange={e => getParamsJson(e)} hidden accept="application/JSON" type="file" />
                  </Button>
                  <Button
                    style={{ margin: 5 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={handleOpenChatbotModal}
                  >
                    {i18n.t("chatbot.buttons.add")}
                  </Button>
                </>
              }
            />
            <Card>
              <TextField
                size="small"
                style={{ padding: '20px' }}
                placeholder={i18n.t("chatbot.searchPlaceholder")}
                type="search"
                value={searchParam}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("chatbot.table.name")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("chatbot.table.updatedAt")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("chatbot.table.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {chatbot.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, name, updatedAt } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="center">{name}</TableCell>
                            <TableCell align="center">{format(parseISO(updatedAt), "dd/MM/yyyy HH:mm")}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setChatbotDiagramModalOpen(true);
                                  setEditFlowChatbot(row);
                                }}
                              >
                                <AccountTreeIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => handleEditChatbot(row)}
                              >
                                <AutofpsSelectIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  downloadObjectAsJson(row);
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setConfirmModalOpen(true);
                                  setDeletingChatbot(row);
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {loading && <TableRowSkeleton columns={3} />}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")} ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={chatbot.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default chatbot;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

