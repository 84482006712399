import React, { useState } from "react";
import { Popover, Typography, Box } from "@mui/material";
import ContactCard from "../ContactCard";
import TicketContactInfo from "../TicketContactInfo";
import ContactModal from "../ContactModal";
import { identifyLocationNumber, formatBrazilianNumber } from "../../utils/IdentifyLocationNumber";

const TicketInfo = ({ contact, ticket, gestor }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const location = identifyLocationNumber(contact?.number);

    return (
        <>
            <div 
                onClick={handlePopoverOpen} 
                style={{ cursor: 'pointer' }}
            >
                <TicketContactInfo
                    profilePicUrl={contact.profilePicUrl}
                    name={contact?.name || 'Contato'}
                    ContatoTelefone={gestor?.ContatoTelefone[0]?.Numero || null}
                    DescricaoRota={gestor?.DescricaoRota || null}
                    assigned={ticket.user ? `Assigned to ${ticket.user.name}` : null}
                    contract={contact?.contract ? `Contract: ${contact.contract}` : null}
                />
            </div>

            {/* Popover substitui o Menu */}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <ContactCard
                        contact={{
                            id: contact?.id,
                            profilePicUrl: contact?.profilePicUrl,
                            name: contact?.name,
                            extraInfo: contact?.extraInfo,
                            isBlocked: contact?.isBlocked,
                            number: formatBrazilianNumber(contact?.number || null),
                            isGroup: contact?.isGroup,
                            email: contact?.email,
                            contract: contact?.contract,
                            description: contact.description,
                            location: location && `${location?.country}, ${location?.state} - ${location?.city}`,
                            tags: contact?.tags || []
                        }}
                        gestor={gestor}
                        setModalOpen={setModalOpen}
                        modalOpen={modalOpen}
                    />
                </Box>
            </Popover>

            <ContactModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                contactId={contact.id}
            />
        </>
    );
};

export default TicketInfo;
