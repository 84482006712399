import { useState, useEffect } from "react";
import { Drawer, Box, CircularProgress, Typography } from "@mui/material";
import api from "../../services/api";
import KanbanDetails from "../KanbanDetails";

const KanbanCardDrawer = ({ open, onClose, cardId, assigneds, priorities }) => {

    const [loading, setLoading] = useState(true);
    const [card, setCard] = useState(null);

    useEffect(() => {
        if (open && cardId) {
            (async () => {
                setLoading(true);
                try {
                    const { data } = await api.get(`/kanban/card/${cardId}`);
                    setCard(data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [open, cardId]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => onClose(false)}
            PaperProps={{
                sx: {
                    width: 800,
                    p: 0,
                    backgroundColor: '#ffffff00',
                    backdropFilter: 'blur(20px)',
                },
            }}
        >
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        gap: 2,
                    }}
                >
                    <CircularProgress size={40} />
                    <Typography variant="body1" color="textSecondary">
                        Carregando dados...
                    </Typography>
                </Box>
            ) : (
                <KanbanDetails card={card} priorities={priorities} assigneds={assigneds} />
            )}
        </Drawer>
    );
};

export default KanbanCardDrawer;
