import React, { useContext, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { ButtonGroup, Grid, ListItemText, MenuItem, Select } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ToastError from "../../toast/error/toastError";

const filter = createFilterOptions({
	trim: true,
});


function telefone_validation(telefone) {
	telefone = telefone.replace(/\D/g, '');
	if (telefone.length < 10 || telefone.length > 11) return false;
	if (telefone.length === 11 && telefone[2] !== '9') return false;
	for (let n = 0; n < 10; n++) {
		if (telefone === String(n).repeat(11) || telefone === String(n).repeat(12)) return false;
	}
	const codigosDDD = [
		11, 12, 13, 14, 15, 16, 17, 18, 19,
		21, 22, 24, 27, 28, 31, 32, 33, 34,
		35, 37, 38, 41, 42, 43, 44, 45, 46,
		47, 48, 49, 51, 53, 54, 55, 61, 62,
		64, 63, 65, 66, 67, 68, 69, 71, 73,
		74, 75, 77, 79, 81, 82, 83, 84, 85,
		86, 87, 88, 89, 91, 92, 93, 94, 95,
		96, 97, 98, 99
	];
	if (!codigosDDD.includes(parseInt(telefone.substring(0, 2)))) return false;
	//if (telefone.length === 10 && ![2, 3, 4, 5, 7].includes(parseInt(telefone.substring(2, 3)))) {return false;}
	return true;
}


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const NewTicketModal = ({ modalOpen, onClose, initialContact = {} }) => {
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState("");
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);
	const [selectOption, setSelectOption] = useState("search");
	const [phone, setPhone] = useState('');

	const handleChange = (event) => {
		let formattedPhone = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
		if (formattedPhone.length <= 10) {
			formattedPhone = formattedPhone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'); // Formata para (11) 9629-95342
		} else {
			formattedPhone = formattedPhone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'); // Formata para (11) 96299-5342
		}
		if (formattedPhone.length > 15) return;
		setPhone(formattedPhone);
	};

	useEffect(() => {
		if (initialContact.id !== undefined) {
			setOptions([initialContact]);
			setSelectedContact(initialContact);
		}
	}, [initialContact]);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("listContacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					ToastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSaveTicket = async contactId => {
		if (!contactId) return;
		if (selectedQueue === "" && user.profile !== 'admin') {
			ToastError("Selecione um setores");
			return;
		}
		setLoading(true);
		try {
			const queueId = selectedQueue !== "" ? selectedQueue : null;
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				queueId,
				userId: user.id,
				status: "open",
			});
			onClose(ticket);
		} catch (err) {
			ToastError(err);
		}
		setLoading(false);
	};

	const handleSaveTicketNewNumber = async e => {

		if (!phone) return;

		if (!telefone_validation(phone)) {
			ToastError("Número invalido, tente novamente.");
			return;
		}

		if (selectedQueue === "" && user.profile !== 'admin') {
			ToastError("Selecione um setor");
			return;
		}
		setLoading(true);
		try {
			const queueId = selectedQueue !== "" ? selectedQueue : null;
			const { data: ticket } = await api.post("/tickets", {
				contactId: null,
				queueId,
				number: phone,
				userId: user.id,
				status: "open",
			});
			onClose(ticket);
		} catch (err) {
			ToastError(err);
		}
		setLoading(false);
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			setNewContact({ name: newValue.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (options, params) => {
		const filtered = filter(options, params);
		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}
		return filtered;
	};

	const renderSelect = (selection) => {
		setSelectOption(selection)
	}

	const renderOption = (props, option) => {
		//(props, option) => <li {...props}>{option.title}</li>
		if (option.number) {
			return <> <li {...props}>{option.name} - {option.number} </li></>;
		} else {
			return <>
				<li {...props}>{i18n.t("newTicketModal.add")} - {option.name} </li>
			</>;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	const renderContactAutocomplete = () => {
		if (initialContact === undefined || initialContact.id === undefined) {
			return (
				<Grid xs={12} item>
					<Autocomplete
						options={options}
						loading={loading}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						filterOptions={createAddContactOption}
						clearOnBlur
						autoHighlight
						getOptionLabel={renderOptionLabel}
						selectOnFocus
						handleHomeEndKeys
						renderOption={renderOption}
						freeSolo
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								onKeyPress={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleSaveTicket(selectedContact.id);
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</Grid>
			)
		}
		return null;
	}

	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
			/>
			<Dialog
				open={modalOpen}
				onClose={handleClose}
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle id="form-dialog-title">
					{i18n.t("newTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<ButtonGroup variant="contained" sx={{ display: 'flex', marginBottom: '10px' }} aria-label="outlined primary button group">
						<Button disabled={selectOption === 'search'} sx={{ width: '50%' }} onClick={e => renderSelect('search')} >{i18n.t("newTicketModal.searchContact")}</Button>
						<Button disabled={selectOption === 'create'} sx={{ width: '50%' }} onClick={e => renderSelect('create')} >{i18n.t("newTicketModal.newContact")}</Button>
					</ButtonGroup>
					<div style={{
						display: 'flex',
						maxWidth: '280px',
						padding: '15px 0px'
					}}>
						{selectOption === 'search' ? <p>{i18n.t("newTicketModal.info.searchContactInfo")}</p> : <p>{i18n.t("newTicketModal.info.newContactInfo")}</p>}
					</div>
					<div>
						<Grid style={{ width: 300 }} container spacing={2}>
							{selectOption === 'create' && <>
								<Grid xs={12} item>
									<TextField
										value={phone}
										onChange={handleChange}
										label={i18n.t("newTicketModal.newNumber")}
										fullWidth
									/>
								</Grid>
							</>}
							{selectOption === 'search' && <> {renderContactAutocomplete()}</>}
							<Grid xs={12} item>
								<Select
									fullWidth
									displayEmpty
									variant="outlined"
									value={selectedQueue}
									onChange={(e) => {
										setSelectedQueue(e.target.value)
									}}
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getcontentanchorel: null,
									}}
									renderValue={() => {
										if (selectedQueue === "") {
											return i18n.t("userType.translate.selectQueue")
										}
										const queue = user.queues.find(q => q.id === selectedQueue)
										return queue.name
									}}
								>
									{user.queues?.length > 0 &&
										user.queues.map((queue, key) => (
											<MenuItem dense key={key} value={queue.id}>
												<ListItemText primary={queue.name} />
											</MenuItem>
										))}
								</Select>
							</Grid>
						</Grid>

					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!selectedContact && phone.length < 14}
						onClick={(e) => { selectOption === "search" ? handleSaveTicket(selectedContact.id) : handleSaveTicketNewNumber(phone) }}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
