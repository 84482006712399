import React, { useState } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import Image from '../Image'; // Ajuste o caminho se necessário
import CloseIcon from '@mui/icons-material/Close';
import './ImageDialog.css'; // Importe o CSS para estilização
import VisibilityIcon from '@mui/icons-material/Visibility';

const ImageLightBox = ({ src, alt, ...props }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="image-container" onClick={handleOpen}>
                <Image src={src} alt={alt} {...props} />
                <div className="overlay">
                    <span className="message"><VisibilityIcon /></span>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md" // Pode ser alterado conforme necessário
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <DialogContent style={{ padding: 0, position: 'relative' }}>
                    <IconButton
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            color: 'white', // Ou outra cor conforme seu tema
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Image src={src} alt={alt} style={{ width: '100%', height: 'auto' }} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ImageLightBox;
