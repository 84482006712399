import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';

function TicketContactInfo({ profilePicUrl, ContatoTelefone, DescricaoRota, name, assigned, contract }) {

    const isSmallScreen = useMediaQuery('(max-width:1000px)');

    return (
        <Box display="flex" alignItems="center">
            {!isSmallScreen && <Avatar src={profilePicUrl} alt="user-pic" sx={{ width: '30px', height: '30px' }} />}
            <Box ml={1}>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ marginBottom: '0px !important' }}
                >
                    {name || ""}
                </Typography>
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={{ marginBottom: '0px !important' }}
                    color="textSecondary"
                >
                    {contract || assigned || ""}
                </Typography>
            </Box>
        </Box>
    );
}

export default TicketContactInfo;
