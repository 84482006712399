// React
import React, { useContext, useEffect, useRef, useState } from "react";

// React Router
import { Link } from 'react-router-dom';

// MUI Core
import { Avatar, Box, Card, CardHeader, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

// Context e Hooks
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from '../../hooks/useSettings';

// Tradução
import { i18n } from "../../translate/i18n";

// Componentes
import Iconify from '../../components/Iconify';
import ToastError from "../../toast/error/toastError";
import Chart from "./Chart";

// MUI Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import TimelineIcon from '@mui/icons-material/Timeline';

// MUI Lab e X-Date-Pickers
import LoadingButton from '@mui/lab/LoadingButton';
import { Chip } from '@mui/material';
import Rating from '@mui/material/Rating';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Serviço API
import api from "../../services/api";

// Seções e Widgets
import { BookingWidgetSummary } from '../../sections/@dashboard/general/booking';

// Data Manipulation
import dayjs from "dayjs";

// Page
import Page from "../../components/Page";
import UserCardRating from "../../components/UserCardRating";

// Função para calcular a média ponderada com base nas contagens de notas, excluindo contagens nulas ou zero
const IconWrapperStyle = styled('div')(({ theme }) => ({
	width: 40,
	height: 40,
	display: 'flex',
	borderRadius: '50%',
	alignItems: 'center',
	justifyContent: 'center',
	color: theme.palette.primary.main,
	backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

const useStyles = makeStyles()((theme) => {
	return {
		container: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			marginTop: '50px'
		},
		fixedHeightPaper: {
			padding: theme.spacing(2),
			display: "flex",
			overflow: "auto",
			flexDirection: "column",
			borderRadius: '15px'
		},
	}
});

const Dashboard = () => {

	const { classes } = useStyles();
	const { user } = useContext(AuthContext);
	const { themeStretch } = useSettings();

	const [loading, setLoading] = useState(true);
	const [loadingQueue, setLoadingQueue] = useState(true);
	const [loadingTickets, setLoadingTickets] = useState(true);
	const [loadingUsers, setLoadingUsers] = useState(true);

	const date = useRef(new Date().toISOString());
	const [tickets, setTickets] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [users, setUsers] = useState([]);
	const [queues, setQueues] = useState([]);
	const [ticketsTimeStart, setTicketsTimeStart] = useState([]);
	const [ticketsTimeEnd, setTicketsTimeEnd] = useState([]);
	const [dateTicketsStart, setDateTicketsStart] = useState(dayjs());
	const [dateTicketsEnd, setDateTicketsEnd] = useState(dayjs());
	const [ticketsByStatus, setTicketsByStatus] = useState({ pending: 0, open: 0, closed: 0 });
	const [openViewTickets, setOpenViewTickets] = useState([]);
	const [filtroNotas, setFiltroNotas] = useState({ regra: 'todos', valor: 0 });
	const notas = [0, 1, 2, 3, 4, 5];

	const CountTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} placement="right" arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: 'transparent',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 500,
			fontSize: theme.typography.pxToRem(12),
		},
	}));

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} placement="right" arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: 'transparent',
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: theme.typography.pxToRem(12),
		},
	}));

	const UserItemSkeleton = () => {
		const skeletonCount = 8; // Altere conforme necessário
		return Array.from({ length: skeletonCount }, (_, index) => (
			<Stack key={index} direction="row" alignItems="center" spacing={2}>
				<Stack direction="row" alignItems="center" spacing={2}>
					<Skeleton variant="circular" width={40} height={40} />
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle2">
							<Skeleton variant="text" width={100} />
						</Typography>
					</Box>
				</Stack>
				<Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
					<Skeleton variant="text" width={20} height={20} />
				</Box>
			</Stack>
		));
	};

	const UserRatingSkeleton = () => {
		const skeletonCount = 8; // Altere conforme necessário
		return Array.from({ length: skeletonCount }, (_, index) => (
			<React.Fragment key={index}>
				<Divider />
				<Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
					<Skeleton variant="circular" width={40} height={40} />
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle2">
							<Skeleton variant="text" width={100} />
						</Typography>
					</Box>
					<Chip label={<Skeleton variant="text" width={60} />} variant="outlined" />
					<Chip label={<Skeleton variant="text" width={80} />} sx={{ width: 100 }} />
				</Stack>
			</React.Fragment>
		));
	};


	function UserItem({ user, index }) {
		return (
			<Stack direction="row" alignItems="center" spacing={2}>
				<Stack direction="row" alignItems="center" spacing={2}>
					<Avatar alt={user.name} src={user.media} />
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle2">{user.name}</Typography>
						<HtmlTooltip
							title={
								<React.Fragment>
									<UserCardRating user={user} />
								</React.Fragment>
							}
						>
							<div>
								<Rating size="small" value={+(user?.total_rating)} precision={0.1} readOnly />
							</div>
						</HtmlTooltip>
					</Box>
				</Stack>
				<Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
					<p style={{ marginRight: '5px' }} >{`${user.total_tickets}`}</p>
					<IconWrapperStyle
						sx={{
							...(index === 0 && {
								color: 'success.main',
								bgcolor: (theme) => alpha(theme.palette.success.main, 0.08),
							}),
							...(index === 1 && {
								color: 'info.main',
								bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
							}),
							...(index === 2 && {
								color: 'error.main',
								bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
							}),
							...(index > 2 && {
								color: '#717171',
								bgcolor: '#6a6a6a14',
							}),
						}}
					>
						<Iconify icon={'ant-design:trophy-filled'} width={20} height={20} />
					</IconWrapperStyle>
				</Box>
			</Stack>
		);
	}

	function UserRating({ ticket, index }) {
		return (
			<>
				<Link to={`/tickets/${ticket.uuid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
					<Divider />
					<Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
						<Avatar alt={ticket.contact.name} src={ticket?.contact?.profilePicUrl} />
						<Box sx={{ flexGrow: 1 }}>
							<Typography variant="subtitle2">{ticket.contact.name}</Typography>
						</Box>
						<Chip
							avatar={<Avatar alt={ticket?.user?.name?.trim().split(" ")[0]} src={ticket?.user?.media} />}
							label={ticket?.user?.name?.trim().split(" ")[0]}
							variant="outlined"
						/>
						<Chip label={ticket?.queue?.name ? ticket?.queue?.name : "Sem fila"} sx={{ bgcolor: ticket?.queue?.color ? ticket?.queue?.color : '', borderColor: ticket?.queue?.color ? ticket?.queue?.color : '' }} />
					</Stack>
				</Link>
			</>
		);
	}


	const fetchAllData = async () => {
		try {
			setLoading(true);

			const formatDateStart = new Date(dateTicketsStart).toISOString();
			const formatDateEnd = new Date(dateTicketsEnd).toISOString();

			if (formatDateEnd === ticketsTimeEnd && formatDateStart === ticketsTimeStart) {
				setLoading(false);
				return;
			}

			setTicketsTimeEnd(formatDateEnd);
			setTicketsTimeStart(formatDateStart);

			const params = { dateStart: formatDateStart, dateEnd: formatDateEnd };

			const fetchTickets = async () => {
				setLoadingTickets(true);
				try {
					const { data } = await api.get("/tickets-dashboard", { params });
					setTickets(data.tickets);
					setChartData(data.relatory);
					setTicketsByStatus(data.count);
				} catch (err) {
					ToastError(err);
				} finally {
					setLoadingTickets(false);
				}
			};

			const fetchQueues = async () => {
				setLoadingQueue(true);
				try {
					const { data } = await api.get("/queue-dashboard", { params });
					setQueues(data);
				} catch (err) {
					ToastError(err);
				} finally {
					setLoadingQueue(false);
				}
			};

			const fetchUsers = async () => {
				setLoadingUsers(true);
				try {
					const { data } = await api.get("/users-dashboard", { params });
					setUsers(data.users);
				} catch (err) {
					ToastError(err);
				} finally {
					setLoadingUsers(false);
				}
			};

			await Promise.allSettled([fetchTickets(), fetchQueues(), fetchUsers()]);

		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};


	useEffect(() => {
		fetchAllData()
	}, []);

	useEffect(() => {
		const getOpenTickets = tickets.filter((ticket) => ticket.status === 'open');
		setOpenViewTickets(getOpenTickets);
	}, [tickets]);

	const {
		pending_count: pending,
		open_count: open,
		closed_count: closed,
		total_count
	} = ticketsByStatus;

	const greetingMessage = () => {
		var greetings = [
			`${i18n.t("salutation.gooddawn")}`,
			`${i18n.t("salutation.goodmorning")}`,
			`${i18n.t("salutation.goodafternoon")}`,
			`${i18n.t("salutation.goodnight")}`];
		let h = new Date().getHours();
		return greetings[(h / 6) >> 0];
	}

	const changeDateStart = e => {
		setDateTicketsStart(e)
	}

	const changeDateEnd = e => {
		setDateTicketsEnd(e)
	}

	function TicketsPerUser({ loadingRating }) {

		const handleFilterChange = (event) => {
			const { name, value } = event.target;
			setFiltroNotas(prevState => ({ ...prevState, [name]: value }));
		};

		const usuariosFiltrados = users.filter(user => {
			switch (filtroNotas.regra) {
				case 'maior':
					return +user.total_rating >= +filtroNotas.valor;
				case 'menor':
					return +user.total_rating <= +filtroNotas.valor;
				case 'igual':
					return +user.total_rating === +filtroNotas.valor;
				default:
					return true;
			}
		});
		
		return (
			<Card>
				<Box sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexWrap: 'wrap',
					marginBottom: 2
				}}>
					<CardHeader title="Ranking" />
					<Box sx={{ padding: '10px 5px 0px 0px' }}>
						<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
							<InputLabel id="select-notas-atendimento-range">Filtro</InputLabel>
							<Select
								labelId="select-notas-atendimento-range"
								id="select-notas-range"
								size="small"
								name="regra"
								value={filtroNotas.regra}
								onChange={handleFilterChange}
								label="Filtro"
							>
								<MenuItem value="todos">Todos</MenuItem>
								<MenuItem value="maior">Maior igual á</MenuItem>
								<MenuItem value="menor">Menor igual á</MenuItem>
								<MenuItem value="igual">Igual a</MenuItem>
							</Select>
						</FormControl>
						{filtroNotas.regra != "todos" && (
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<InputLabel id="select-notas-atendimento">Nota</InputLabel>
								<Select
									labelId="select-notas-atendimento"
									id="select-notas"
									label="Nota"
									size="small"
									name="valor"
									value={filtroNotas.valor}
									onChange={handleFilterChange}
								>
									{notas.map(nota => <MenuItem key={nota} value={nota}>{nota}</MenuItem>)}
								</Select>
							</FormControl>
						)}
					</Box>
				</Box>
				<Stack spacing={3} sx={{
					p: 3,
					maxHeight: '360px',
					minHeight: '360px',
					overflow: 'scroll'
				}}>
					{loadingRating ?
						<UserItemSkeleton /> :
						usuariosFiltrados.map((user, index) => (
							<UserItem key={user.id} user={user} index={index} />
						))}
				</Stack>
			</Card>
		);
	}

	function TicketsCloseds({ loadingTickets }) {
		return (
			<Card>
				<CardHeader title="Atendimentos ativos" />
				<Stack spacing={3} sx={{
					p: 3,
					maxHeight: '392px',
					minHeight: '392px',
					overflow: 'scroll'
				}}>
					{loadingTickets ?
						<UserRatingSkeleton />
						:
						openViewTickets.map((ticket, index) => (
							<UserRating key={ticket.id} loadingTickets={loadingTickets} ticket={ticket} index={index} />
						))}
				</Stack>
			</Card>
		);
	}

	return (
		<div style={{ margin: '65px 0px 65px 0px' }}>
			<Page title="Dashboard">
				{
					/*
					<Backdrop
						sx={{ backgroundColor: '#fff0', color: '#fff', zIndex: (theme) => theme.zIndex.drawer }}
						open={loading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					*/
				}
				   <Container maxWidth={false}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Typography variant="h3">{`${greetingMessage()}, ${user.name}!`}</Typography>
						</Grid>
						<Grid item xs={12} md={12}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px', display: 'flex', alignItems: 'center' }}>
									<div style={{ margin: '5px' }}>
										<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
											<DesktopDatePicker
												sx={{ '& .MuiOutlinedInput-input': { padding: '7.5px 14px' } }}
												label="Data inicial"
												slotProps={{ textField: { size: 'small', fullWidth: true } }}
												inputFormat="DD/MM/YYYY"
												value={dayjs(dateTicketsStart)}
												maxDate={dayjs(new Date())}
												onChange={(newValue) => changeDateStart(newValue)}
											/>
										</LocalizationProvider>
									</div>
									<div style={{ margin: '5px' }}>
										<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
											<DesktopDatePicker
												sx={{ '& .MuiOutlinedInput-input': { padding: '7.5px 14px' } }}
												slotProps={{ textField: { size: 'small', fullWidth: true } }}
												label="Data final"
												inputFormat="DD/MM/YYYY"
												value={dayjs(dateTicketsEnd)}
												maxDate={dayjs(new Date())}
												onChange={(newValue) => changeDateEnd(newValue)}
											/>
										</LocalizationProvider>
									</div>
									<div>
										<LoadingButton startIcon={<ManageSearchIcon />} loading={loading} variant="contained" onClick={() => fetchAllData()}>Buscar</LoadingButton>
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} md={3}>
							<BookingWidgetSummary title={i18n.t("dashboard.tickets.ticketsPending")} total={pending} icon={<HourglassBottomIcon style={{ width: '50%', height: '50%' }} sx={{ color: (theme) => theme.palette.primary.main }} />} />
						</Grid>
						<Grid item xs={6} md={3}>
							<BookingWidgetSummary title={i18n.t("dashboard.tickets.ticketsOpen")} total={open} icon={<RecordVoiceOverIcon style={{ width: '50%', height: '50%' }} sx={{ color: (theme) => theme.palette.primary.main }} />} />
						</Grid>
						<Grid item xs={6} md={3}>
							<BookingWidgetSummary title={i18n.t("dashboard.tickets.ticketsClosed")} total={closed} icon={<CheckCircleOutlineIcon style={{ width: '50%', height: '50%' }} sx={{ color: (theme) => theme.palette.primary.main }} />} />
						</Grid>
						<Grid item xs={6} md={3}>
							<BookingWidgetSummary title={i18n.t("dashboard.tickets.ticketsTotal")} total={Number(total_count)} icon={<TimelineIcon style={{ width: '50%', height: '50%' }} sx={{ color: (theme) => theme.palette.primary.main }} />} />
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
							<TicketsPerUser loadingRating={loadingTickets || loadingQueue || loadingUsers} />
						</Grid>
						<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
							<TicketsCloseds loadingTickets={loadingTickets || loadingQueue || loadingUsers} />
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
							<Paper sx={{ boxShadow: 2 }} className={classes.fixedHeightPaper}>
								<Chart chartData={chartData} />
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</Page>
		</div>
	);
};

export default Dashboard;
