import { Box, Typography } from "@mui/material";
import MarkdownText from "../MarkdownText";
import { styled } from '@mui/material/styles';


const StyledRoot = styled(Box)(({ theme }) => ({
    position: 'relative',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
    },
    '&:hover': {
        zIndex: 9,
        position: 'relative',
    },
}));

const StyledTextContent = styled((props) => (
    <Typography component="span" noWrap {...props} />
))(({ theme }) => ({
    ...theme.typography.body2,
    flexGrow: 1,
}));


const RenderMarkDownMessage = ({ children }) => {
    return (
        <StyledRoot>
            <StyledTextContent sx={{ whiteSpace: 'pre-line' }}>
                <MarkdownText component="span" sx={{
                    color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000',
                }}>
                    {children}
                </MarkdownText>
            </StyledTextContent>
        </StyledRoot>
    )
};

export default RenderMarkDownMessage;

