import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar, IconButton } from '@mui/material';
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import AvatarPresence from '../../components/AvatarPresence';
import DailyPassword from '../../helpers/DailyPassword';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { toast } from "react-toastify";
import ToastSuccess from '../../toast/success/toastSuccess';

function truncateName(name) {
  const words = name.split(' ');
  if (words.length <= 2) {
    return name;
  }
  const truncatedName = words.slice(0, 2).join(' ');
  return truncatedName;
}


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

const profilesNames = {
  admin: i18n.t("userType.translate.admin"),
  adminSuper: i18n.t("userType.translate.superUser"),
  supervisor: i18n.t("userType.translate.supervisor"),
  user: i18n.t("userType.translate.user"),
}
export default function NavbarAccount({ isCollapse }) {

  const { user, status } = useContext(AuthContext);
  const [dailyPassword, setDailyPassword] = useState(DailyPassword())

  function copyTextToClipboard() {
    const textToCopy = document.getElementById('dailyPassword').textContent;
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    ToastSuccess("Senha diaria copiada com sucesso!");

  }

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <AvatarPresence
          id="Status"
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          status={status}>
          <Avatar src={user?.driveUrl ?? user?.media} alt={user.name} />
        </AvatarPresence>
        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Box sx={{ marginBottom: '5px' }}>
            <Typography variant="subtitle2" noWrap>
              {user?.name && truncateName(user.name)}
            </Typography>
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {profilesNames[user.profile]}
            </Typography>
          </Box>
          {!isCollapse && user.companyId == 2 && (
            <Box>
              <Typography variant="subtitle2" noWrap>
                Senha do dia:
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography id="dailyPassword" variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                  {dailyPassword}
                </Typography>
                <IconButton onClick={() => copyTextToClipboard()} aria-label="delete">
                  <ContentCopyIcon sx={{ width: '15px', height: '15px' }} />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </RootStyle>
    </Link>
  );
}
