import { addDays, addMonths, differenceInDays, format, getDaysInMonth, getDay, getDate, setHours, setDate } from 'date-fns';

/**
 * Helper que cria eventos recorrentes com base na data de início, data de fim e dias da semana permitidos.
 * Se contentInMonth for true, mantém a regra antiga; se for false, aplica a nova lógica de agrupamento.
 *
 * @param {Object} item - O objeto de evento original que contém as informações de startAt, endAt, title, days e type.
 * @returns {Array} - Um array de eventos formatados com base na recorrência.
 */
const CreateRecurringEvents = (item) => {
    const reformattedArray = [];
    const startDate = new Date(item.startAt);
    // Verifica se endAt está vazio e define como 4 meses após o startAt se necessário
    const endDate = item.endAt ? new Date(item.endAt) : addMonths(startDate, item?.recurrence === 'ANNUALLY' ? 60 : 12);
    const dayDifference = differenceInDays(endDate, startDate);
    const recurrenceDays = item.days ? JSON.parse(item.days) : null;
    const dayOfMonth = getDate(startDate);

    // Lógica de recorrência
    switch (item.recurrence) {
        case 'ONCE':
            reformattedArray.push({
                id: item.id,
                title: item.title,
                start: `${format(startDate, 'yyyy-MM-dd')}T${item.startTime}`,
                end: `${format(startDate, 'yyyy-MM-dd')}T${item.endTime || item.startTime}`,
                extendedProps: { ...item },
            });
            break;

        case 'WEEKLY':
            for (let i = 0; i <= dayDifference; i++) {
                const currentStartDate = addDays(startDate, i);
                const dayOfWeek = getDay(currentStartDate);

                // Verifica se deve mostrar o evento com base nos dias da semana permitidos
                if (recurrenceDays) {
                    const shouldShowEvent =
                        (dayOfWeek === 0 && recurrenceDays.sunday) ||
                        (dayOfWeek === 1 && recurrenceDays.monday) ||
                        (dayOfWeek === 2 && recurrenceDays.tuesday) ||
                        (dayOfWeek === 3 && recurrenceDays.wednesday) ||
                        (dayOfWeek === 4 && recurrenceDays.thursday) ||
                        (dayOfWeek === 5 && recurrenceDays.friday) ||
                        (dayOfWeek === 6 && recurrenceDays.saturday);

                    if (!shouldShowEvent) continue; // Se não for um dia permitido, continua para a próxima iteração
                }

                const formattedDate = format(currentStartDate, 'yyyy-MM-dd');
                reformattedArray.push({
                    id: item.id + '_' + i,
                    title: item.title,
                    start: `${formattedDate}T${item.startTime}`,
                    end: `${formattedDate}T${item.endTime || item.startTime}`,
                    extendedProps: { ...item },
                });
            }
            break;

        case 'MONTHLY':

            for (let i = 0; i < 12; i++) {
                const currentStartDate = addMonths(startDate, i);

                // Verifica se o dia existe no mês atual
                const maxDayInMonth = getDaysInMonth(currentStartDate);

                // Se o dia não existir, ajusta para o último dia do mês
                const adjustedDay = dayOfMonth > maxDayInMonth ? maxDayInMonth : dayOfMonth;

                // Ajusta a data para garantir que a hora seja 00:00
                const adjustedDate = setHours(setDate(currentStartDate, adjustedDay), 0, 0, 0);

                reformattedArray.push({
                    id: item.id + '_' + i,
                    title: item.title,
                    start: `${format(adjustedDate, 'yyyy-MM-dd')}T${item.startTime}`,
                    end: `${format(adjustedDate, 'yyyy-MM-dd')}T${item.endTime || item.startTime}`,
                    extendedProps: { ...item },
                });
            }
            break;

        case 'ANNUALLY':
            const month = startDate.getMonth();
            for (let i = 0; i < 5; i++) {
                const currentStartDate = addMonths(startDate, i * 12);
                const adjustedDate = setDate(currentStartDate, dayOfMonth);
                // Verifica se o dia existe no mês atual
                if (adjustedDate.getMonth() === month) {
                    reformattedArray.push({
                        id: item.id + '_' + i,
                        title: item.title,
                        start: `${format(adjustedDate, 'yyyy-MM-dd')}T${item.startTime}`,
                        end: `${format(adjustedDate, 'yyyy-MM-dd')}T${item.endTime || item.startTime}`,
                        extendedProps: { ...item },
                    });
                }
            }
            break;

        default:
            console.warn(`Tipo de recorrência desconhecido: ${item.recurrence}`);
    }

    return reformattedArray;
};

export default CreateRecurringEvents;
