import React, { useContext, useEffect, useReducer, useState } from "react";

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {
    Button,
    Card,
    Checkbox,
    Container,
    FormControlLabel,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import ChatBotModal from "../../components/ChatbotModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QueueModal from "../../components/QueueModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from '../../hooks/useSettings';
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import SmartToyIcon from '@mui/icons-material/SmartToy';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import socket from "../../hooks/useSocket";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";


const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[queueIndex] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[queueIndex] = queue;
      return [...state];
    } else {
      return [queue, ...state];
    }
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Queues = () => {
  const { classes } = useStyles();

  const [queues, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const { themeStretch } = useSettings();
  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [queuesChatBotOpen, setQueuesChatBotOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { companyId } = user;
  const [definitionBot, setDefinitionBot] = useState('default');
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/queue");
        dispatch({ type: "LOAD_QUEUES", payload: data });

        setLoading(false);
      } catch (err) {
        ToastError(err);
        setLoading(false);
      }
    })();
  }, []);


  useEffect(() => {
    (async () => {
      try {
        if (!companyId) return;
        const { data } = await api.get(`/company/${companyId}`);
        setDefinitionBot(data.typeMessage)
      } catch (err) {
        setDefinitionBot(user.company.typeMessage)
      }
    })();
  }, []);


  useEffect(() => {
    

    
socket.on("queue", (data) => {
      if ((data.action === "update" || data.action === "create") && companyId == data.queue.companyId) {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.off("queue");
    };
  }, []);

  // ------------  Definições de chatbot  --------------

  const handleOpenChatBotModal = () => {
    setQueuesChatBotOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseChatBotModal = () => {
    setQueuesChatBotOpen(false);
    setSelectedQueue(null);
  };

  // --------------------------------------------------


  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
      ToastSuccess(i18n.t("Queue deleted successfully!"));
    } catch (err) {
      ToastError(err);
    }
    setSelectedQueue(null);
  };

  const setNewDefinition = async (e) => {
    var state = 'default';


    if (definitionBot === 'list') {
      state = 'default';
    } else {
      state = 'list';
    }
    try {
      await api.put(`/changeList/${companyId}`, {
        typeMessage: state,
        userId: user.id || null,
      });
      var typeMessage = state === 'default' ? i18n.t("userType.translate.defaultSuccess") : i18n.t("userType.translate.listSuccess")
      ToastSuccess(`${i18n.t("userType.translate.changeSuccess")} ${typeMessage}`);
    } catch (err) {
      ToastError(err);
    }
    setDefinitionBot(state)
  }

  const renderType = (chatbot) => {
    if (chatbot) {
      return (<>
        <Tooltip title={i18n.t("queues.type.chatbot")}>
          <SmartToyIcon />
        </Tooltip>
      </>)
    } else {
      return (<>
        <Tooltip title={i18n.t("queues.type.list")}>
          <FormatListNumberedIcon />
        </Tooltip>
      </>)
    }
  }

  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            selectedQueue &&
            `${i18n.t("queues.confirmationModal.deleteTitle")} ${selectedQueue.name
            }?`
          }
          open={confirmModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={() => handleDeleteQueue(selectedQueue.id)}
        >
          {i18n.t("queues.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <QueueModal
          open={queueModalOpen}
          onClose={handleCloseQueueModal}
          queueId={selectedQueue?.id}
          scheduleId={selectedQueue?.scheduleId}
        />
        <ChatBotModal
          open={queuesChatBotOpen}
          onClose={handleCloseChatBotModal}
        />
        <Page title={i18n.t("queues.title")}>
        <Container maxWidth={false}>
            <HeaderBreadcrumbs
              heading={i18n.t("queues.title")}
              action={
                <>
                  <FormControlLabel
                    style={{ display: 'none' }}
                    control={<Checkbox
                      disabled
                      checked={definitionBot === 'default' ? false : true}
                      onChange={e => setNewDefinition(e)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label={i18n.t("userType.translate.modifyDefinitionsBot")} />
                  <Button
                    style={{ margin: 2, display: 'none' }}
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenChatBotModal}
                    disabled
                  >
                    <SmartToyIcon />
                  </Button>
                  <Button
                    style={{ margin: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={handleOpenQueueModal}
                  >
                    {i18n.t("queues.buttons.add")}
                  </Button>
                </>
              }
            />
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }} style={{ marginTop: '40px' }}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {i18n.t("queues.table.name")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("queues.table.color")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("queues.table.type")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("queues.table.greeting")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("queues.table.messageAccepting")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("queues.table.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {queues.map((queue) => (
                          <TableRow key={queue.id}>
                            <TableCell align="left">{queue.name}</TableCell>
                            <TableCell align="center">
                              <div className={classes.customTableCell}>
                                <span
                                  style={{
                                    backgroundColor: queue.color,
                                    width: 20,
                                    height: 20,
                                    borderRadius: '10px',
                                    alignSelf: "center",
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell align="center"><div style={{ display: 'flex' }}>{renderType(queue.isChatbot)}</div></TableCell>
                            <TableCell align="center">
                              <div className={classes.customTableCell}>
                                <Tooltip title={queue.greetingMessage} arrow>
                                  <Typography
                                    style={{ width: 300, align: "center" }}
                                    noWrap
                                    variant="body2"
                                  >
                                    {queue.greetingMessage}
                                  </Typography>
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <div className={classes.customTableCell}>
                                <Tooltip title={queue.messageAccepting} arrow>
                                  <Typography
                                    style={{ width: 200, align: "center" }}
                                    noWrap
                                    variant="body2"
                                  >
                                    {queue.messageAccepting}
                                  </Typography>
                                </Tooltip>
                              </div>
                            </TableCell>

                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => handleEditQueue(queue)}
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={() => {
                                  setSelectedQueue(queue);
                                  setConfirmModalOpen(true);
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        {loading && <TableRowSkeleton columns={4} />}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default Queues;
