import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/ManageSearch';
import { Box, Button, Divider, FormControlLabel, FormGroup, ListSubheader, Tooltip, Typography } from "@mui/material";
import AccordionSummary from '@mui/material/AccordionSummary';
import Badge from "@mui/material/Badge";
import FormControl from '@mui/material/FormControl';
import InputBase from "@mui/material/InputBase";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import NewTicketModal from "../NewTicketModal/index.js";
import TabPanel from "../TabPanel";
import TicketsList from "../TicketsList";
import TicketsQueueSelect from "../TicketsQueueSelect";
// icons 
import AddIcon from '@mui/icons-material/Add';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import InventoryIcon from '@mui/icons-material/Inventory';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import SwitchStyled from "../SwitchStyled/index.js";
import "./style.css";

const useStyles = makeStyles()((theme) => {
  return {
    tabsHeader: {
      flex: "none",
    },
    settingsIcon: {
      alignSelf: "center",
      marginLeft: "auto",
      padding: 8,
    },

    tab: {
      minWidth: 120,
      width: 120,
      margin: '0px !important'
    },

    ticketOptionsBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: '0px 5px'
    },

    searchInputWrapper: {
      flex: 1,
      display: "flex",
      borderRadius: 40,
      padding: '3px !important',
      marginRight: theme.spacing(1),
    },

    searchIcon: {
      color: "grey",
      marginLeft: 6,
      marginRight: 6,
      alignSelf: "center",
    },

    searchInput: {
      flex: 1,
      border: "none",
      borderRadius: 30,
    },

    ticketsOpenWrapper: {
      overflowY: "scroll",
      width: "100%",
      height: "100%",
    },

    isTabClosed: {
      width: 0,
      height: 0,
    },

    tabTicketIndicator: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      width: '20px',
      height: '20px',
      borderRadius: "50%",
    },
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const getInitialViewTags = () => {
  const savedViewTags = localStorage.getItem("viewTags");
  return savedViewTags !== null ? JSON.parse(savedViewTags) : false;
};

const TicketsManager = () => {

  const { classes } = useStyles();
  const history = useHistory();
  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [ticketsListOpenLength, setTicketsListOpenLength] = useState(0);
  const [ticketsListArchivedLength, setTicketsListArchivedLength] = useState(0);
  const [ticketsListPendingLength, setTicketsListPendingLength] = useState(0);
  const [ticketsListClosedLength, setTicketsListClosedLength] = useState(0);
  const [viewTags, setViewTags] = useState(getInitialViewTags);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(() => {
    const queueIds = userQueueIds || [];
    return [...queueIds, 'no_queue'];
  });
  const [users, setUsers] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [userTickets, setUserTickets] = useState(user.id ?? "");
  const showAllTickets = useRef(false);


  useEffect(() => {
    localStorage.setItem("viewTags", JSON.stringify(viewTags));
  }, [viewTags]);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeTabTicketLength = (value, status) => {
    if (status === 'open') {
      return setTicketsListOpenLength(value)
    }
    if (status === 'archived') {
      return setTicketsListArchivedLength(value)
    }
    if (status === 'pending') {
      return setTicketsListPendingLength(value)
    }
    if (status = 'closed') {
      return setTicketsListClosedLength(value)
    }
  }

  const handleChange = (event) => {
    if (event.target.value == 'all') {
      showAllTickets.current = true;
      setUserTickets("all");
    } else {
      if (typeof event.target.value === 'number') {
        setUserTickets(event.target.value);
        showAllTickets.current = false;
      }
    }
  };

  useEffect(() => {
    let isActive = true;
    let getAllUsers = false;
    const getUser = async () => {
      const { data } = await api.get("/users/", { params: { isActive, getAllUsers } });
      setUsers(data.users)
    }
    getUser();
  }, [user])

  let searchTimeout;
  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
    } else {
      setTab("search")
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const excludeMessage = () => {
    document.getElementById("searchTickets").value = "";
    setTab("open");
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };


  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }

  return (
    <Paper elevation={0} id="contentTicketsManager" variant="outlined" className={"ticketsWrapper"}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            id="openTab"
            value={"open"}
            icon={<AllInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            id="closedTab"
            value={"closed"}
            icon={<LibraryAddCheckIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Divider />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Box component="div" style={{ padding: '10px' }} className={classes.searchInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t("tickets.search.placeholder")}
            type="search"
            onChange={handleSearch}
          />
        </Box>
      </Paper>
      <Divider />
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChangeAccordion('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0, display: 'flex', alignItems: 'center' }}>
            Filtros
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          borderTop: '1px solid rgba(0, 0, 0, .125)',
        }}>
          <>
            <Paper square elevation={0} className={classes.ticketOptionsBox}>
              <>
                <Can
                  role={user.profile}
                  perform="tickets-manager:showall"
                  yes={() => (
                    <>
                      <FormControl sx={{ m: 1, width: "50%" }} size="small">
                        <InputLabel htmlFor="grouped-native-select">Visualizar</InputLabel>
                        <Select
                          defaultValue=""
                          id="grouped-native-select"
                          label="Grouping"
                          value={userTickets}
                          onChange={handleChange}
                          MenuProps={MenuProps}
                        >
                          <ListSubheader>Filtrar</ListSubheader>
                          <MenuItem value={user.id}>Meus atendimentos</MenuItem>
                          <MenuItem value={'all'}>{i18n.t("tickets.buttons.showAll")}</MenuItem>
                          <ListSubheader>Apenas usuário</ListSubheader>
                          {users && users
                            .filter(usr => usr.id !== user.id)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((usr, i) => (
                              <MenuItem key={i} value={Number(usr.id)}>
                                {usr.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                />
                <TicketsQueueSelect
                  size={'50%'}
                  selectedQueueIds={selectedQueueIds}
                  userQueues={user?.queues}
                  onChange={(values) => setSelectedQueueIds(values)}
                />
              </>
            </Paper>
            <Paper square elevation={0} className={classes.ticketOptionsBox}>
              <>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <FormControlLabel
                    control={<SwitchStyled sx={{ marginLeft: '10px' }} />}
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          flexDirection: 'row'
                        }}
                      >
                        <Typography variant="body1" sx={{ fontSize: '13px' }}>
                          Ver Marcadores
                        </Typography>
                      </Box>
                    }
                    checked={viewTags}
                    onChange={e => setViewTags(e.target.checked)}
                  />
                </FormGroup>
              </>
            </Paper>
          </>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Button size="large" id="clickArchive" onClick={() => setTab(tab == 'archived' ? 'open' : 'archived')}>
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography sx={{ display: 'flex', flexShrink: 0 }}>
            {tab == 'archived' && tab !== 'open' ? <ModeCommentIcon fontSize="small" /> : <InventoryIcon fontSize="small" />}
          </Typography>
          <Badge max={999} color="primary" badgeContent={ticketsListArchivedLength}>
            <Typography sx={{ color: 'text.secondary' }}>
              {tab == 'archived' && tab !== 'open' ? <>Voltar para conversas</> : <>Arquivados</>}
            </Typography>
          </Badge>
          {tab == 'archived' && <KeyboardArrowLeftIcon />}
          {tab !== 'archived' && <KeyboardArrowRightIcon />}
        </Box>
      </Button>
      <Divider />
      <TabPanel value={tab} name="open" className={"ticketsWrapper"}>
        <Paper elevation={0} square className={classes.tabsHeader}>
          <Tabs
            value={tabOpen}
            onChange={handleChangeTabOpen}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon label tabs example"
          >
            <Tab
              id="openTickets"
              value={"open"}
              label={<Badge badgeContent={ticketsListOpenLength} max={999} color="primary">{i18n.t("tickets.tabs.open.open")}</Badge>}
              classes={{ root: classes.tab }}
            />
            <Tab
              id="pendingTickets"
              value={"pending"}
              label={<Badge badgeContent={ticketsListPendingLength} max={999} color="primary">{i18n.t("tickets.tabs.open.pending")}</Badge>}
              classes={{ root: classes.tab }}
            />
          </Tabs>
        </Paper>
        <Box
          component="div"
          name="open"
          className={tabOpen === 'open' ? classes.ticketsOpenWrapper : classes.isTabClosed}
        >
          <TicketsList
            noQueue={user.noQueue}
            companyId={user.companyId}
            viewTags={viewTags}
            status="open"
            showAll={showAllTickets.current}
            selectedQueueIds={selectedQueueIds}
            userTickets={userTickets}
            reloadCount={(count) => handleChangeTabTicketLength(count, 'open')}
          />
        </Box>
        <Box
          component="div"
          name="pending"
          className={tabOpen === 'pending' ? classes.ticketsOpenWrapper : classes.isTabClosed}
        >
          <TicketsList
            noQueue={user.noQueue}
            companyId={user.companyId}
            viewTags={viewTags}
            status="pending"
            showAll={showAllTickets.current}
            selectedQueueIds={selectedQueueIds}
            userTickets={userTickets}
            reloadCount={(count) => handleChangeTabTicketLength(count, 'pending')}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tab} name="archived" className={"ticketsWrapper"}>
        <Box
          component="div"
          name="pending"
          className={classes.ticketsOpenWrapper}
        >
          <TicketsList
            noQueue={user.noQueue}
            companyId={user.companyId}
            viewTags={viewTags}
            status="archived"
            showAll={showAllTickets.current}
            selectedQueueIds={selectedQueueIds}
            userTickets={userTickets}
            reloadCount={(count) => handleChangeTabTicketLength(count, 'archived')}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={"ticketsWrapper"}>
        <TicketsList
          noQueue={user.noQueue}
          companyId={user.companyId}
          viewTags={viewTags}
          status="closed"
          showAll={true}
          userTickets={userTickets}
          selectedQueueIds={selectedQueueIds}
          reloadCount={(count) => { }}
        />
      </TabPanel>
      <TabPanel value={tab} name="search" className={"ticketsWrapper"}>
        <TicketsList
          noQueue={user.noQueue}
          companyId={user.companyId}
          viewTags={viewTags}
          searchParam={searchParam}
          showAll={true}
          userTickets={""}
          selectedQueueIds={selectedQueueIds}
          reloadCount={(count) => { }}
        />
      </TabPanel>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '5px'
      }}>
        <Tooltip title="Novo atendimento">
          <Button
            sx={{
              borderRadius: '35px',
              minWidth: '35px',
              padding: '5px'
            }}
            variant="contained"
            color="primary"
            onClick={() => setNewTicketModalOpen(true)}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      </Box>
    </Paper >
  );
};

export default TicketsManager;
