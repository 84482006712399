import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
	Avatar,
	Box,
	Button,
	Chip,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Checkbox,
	Stack,
	Tooltip,
	Typography
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import formatBrazilianNumber from "../../utils/formatBrazilianNumber";
import DialogBlur from "../DialogBlur";
import { playNotificationEventSound } from '../../helpers/NotificationCalendarEvent';

const formatTime = (time) => {
	return time ? time.substring(0, 5) : "00:00";
};

const days = [
	{ label: "Domingo", key: "sunday" },
	{ label: "Segunda", key: "monday" },
	{ label: "Terça", key: "tuesday" },
	{ label: "Quarta", key: "wednesday" },
	{ label: "Quinta", key: "thursday" },
	{ label: "Sexta", key: "friday" },
	{ label: "Sábado", key: "saturday" },
];

const CalendarEventNotification = ({ open, onClose, onConfirm, event }) => {

	if (!event) return null;

	const intervalRef = useRef(null);

	useEffect(() => {
		if (open) {
			playNotificationEventSound();
			intervalRef.current = setInterval(() => {
				playNotificationEventSound();
			}, 5000);
		}
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [open]);

	return (
		<DialogBlur
			open={open}
			aria-labelledby="event-notification-dialog"
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle
				id="event-notification-dialog"
				sx={{ backgroundColor: "#00ab55", color: "#fff", padding: '10px' }}
			>
				<Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
					<NotificationsActiveIcon />
					{event?.title || "Novo Evento"}
				</Stack>
			</DialogTitle>

			<DialogContent dividers>
				{/* Informações do Evento */}
				<Box mb={2}>
					<Stack direction="row" alignItems="center" gap={1}>
						<AccessTimeIcon color="action" />
						<Typography variant="body1" color="textPrimary">
							{formatTime(event?.startTime)}{" "}<strong>às</strong>{" "}{formatTime(event?.endTime)}
						</Typography>
					</Stack>
				</Box>

				{event.days && (
					<Box sx={{ width: { xs: '100%', sm: '100%' }, display: 'flex' }}>
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							{days.map((day) => (
								<Tooltip key={day.key} title={day.label} disableInteractive>
									{/* Remova o fragmento `<>` */}
									<Checkbox
										disabled
										sx={{ padding: '2px' }}
										icon={<Chip label={day.label.charAt(0)} size="small" variant="outlined" />}
										checkedIcon={<Chip label={day.label.charAt(0)} size="small" sx={{ backgroundColor: '#00ab55', color: '#ffffff' }} />}
										checked={JSON.parse(event.days)[day.key]}
									/>
								</Tooltip>
							))}
						</Box>
					</Box>
				)}


				{event?.description && (
					<>
						<Divider>
							<Typography variant="subtitle1" color="textPrimary">
								<strong>Descrição</strong>
							</Typography>
						</Divider>

						<Box sx={{ marginTop: '10px', marginBottom: '10px', maxHeight: '200px', overflowY: 'auto', padding: '10px' }}>
							<Typography variant="body2" color="textSecondary">
								{event?.description || "Sem descrição disponível."}
							</Typography>
						</Box>
					</>
				)}

				{/* Usuários vinculados */}
				{event?.users?.length > 0 && (
					<>
						<Divider >
							<Typography variant="body1" color="textPrimary">
								<strong>Usuários Vinculados</strong>
							</Typography>
						</Divider>
						<Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
							<Box mt={1}>
								<Stack direction="row" spacing={1} flexWrap="wrap">
									{event.users.map((user, index) => (
										<Chip
											size="small"
											key={index}
											label={user.name}
											avatar={<Avatar src={user?.media || user?.driveUrl} alt={user.name} />}
										/>
									))}
								</Stack>
							</Box>
						</Box>
					</>
				)}
				{event?.contacts?.length > 0 && (
					<>
						<Divider>
							<Typography variant="body1" color="textPrimary">
								<strong>Contatos Vinculados</strong>
							</Typography>
						</Divider>
						<Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
							<Box mt={1}>
								<Stack direction="row" spacing={1} flexWrap="wrap">
									{event.contacts.map((contact, index) => (
										<Tooltip title={formatBrazilianNumber(contact.number)}>
											<Chip
												size="small"
												key={index}
												label={contact.name}
												avatar={<Avatar src={contact?.profilePicUrl} alt={contact.name} />}
											/>
										</Tooltip>

									))}
								</Stack>
							</Box>
						</Box>
					</>
				)}
				{event?.tags?.length > 0 && (
					<>
						<Divider>
							<Stack direction="row" alignItems="center" gap={1}>
								<Typography variant="body1" color="textPrimary">
									<strong>Tags</strong>
								</Typography>
							</Stack>
						</Divider>
						<Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
							<Box mt={1}>
								<Stack direction="row" spacing={1} flexWrap="wrap">
									{event.tags.map((tag, index) => (
										<Chip
											size="small"
											variant="outlined"
											key={index}
											label={tag.name}
											avatar={<LocalOfferIcon sx={{ color: tag.color, paddingLeft: '5px' }} />} // Aplica a cor no ícone
											sx={{
												borderRadius: "5px",
												color: tag.color, // Aplica a cor no texto
												borderColor: tag.color, // Aplica a cor na borda (outline)
												'& .MuiChip-avatar': {
													color: tag.color, // Aplica a cor no ícone dentro do avatar, se houver
												},
											}}
										/>

									))}
								</Stack>
							</Box>
						</Box>
					</>
				)}

			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-between", padding: "16px" }}>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						onClose(false);
						onConfirm();
					}}
				>
					Fechar
				</Button>
			</DialogActions>
		</DialogBlur>
	);
};

export default CalendarEventNotification;
