import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css'; // Certifique-se de que esse caminho está correto

// ----------------------------------------------------------------------

hljs.configure({
  // Especifica as linguagens que deseja usar para realce de sintaxe
  languages: ['javascript', 'jsx', 'sh', 'bash', 'html', 'scss', 'css', 'json'],
});

// Verifica se o código está sendo executado em um ambiente de navegador
if (typeof window !== 'undefined') {
  window.hljs = hljs; // Adiciona hljs ao objeto window para acesso global
}

export default hljs; // Exporta hljs para uso em outros módulos
