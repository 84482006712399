import { Chip, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ChecklistIcon from '@mui/icons-material/Checklist';

const ChecklistCount = ({ tasks }) => {
    const [count, setCount] = useState({ total: 0, completed: 0 });

    useEffect(() => {
        const countChecklistComplete = (tasks) => {
            if (!!tasks && tasks?.length === 0) return { total: 0, completed: 0 };
            let totalChecklists = 0;
            let completedChecklists = 0;

            tasks.forEach(task => {
                totalChecklists += task.checklists.length;
                completedChecklists += task.checklists.filter(checklist => checklist.isCompleted).length;
            });

            return { total: totalChecklists, completed: completedChecklists };
        };

        const result = countChecklistComplete(tasks);
        setCount(result);
    }, [tasks]);

    return (
        <Tooltip title={`${count.completed} / ${count.total}`} placement="right">
            <Chip
                variant='outlined'
                color={count.total > count.completed ? 'default' : 'success'}
                size="small"
                icon={< ChecklistIcon />}
                sx={{ borderRadius: '5px' }}
                label={`${count.completed} / ${count.total}`}
            />
        </Tooltip>

    );
};

export default ChecklistCount;
