import dicionario from '../assets/AutoSuggestion/dicionario.json';
import affptbr from '../assets/AutoSuggestion/pt_BR.aff';
const levenshtein = require('fast-levenshtein');

// Função para remover acentos e pontuações
function ingexNormalizarTexto(texto) {
    return texto
        .normalize('NFD') // Normaliza para descompor caracteres acentuados
        .replace(/[\u0300-\u036f]/g, '') // Remove acentos
        .replace(/[.,?!;:]/g, ''); // Remove pontuações
}

// Função para carregar o arquivo .json, limpar palavras, remover duplicatas e manter acentuação
function ingexCarregarDicionario() {
    const { palavras } = dicionario; // Carrega o arquivo JSON e extrai o array de palavras

    // Criar um mapa para armazenar as palavras normalizadas e suas formas originais
    const ingexDicionario = new Map();
    palavras.forEach(palavra => {
        const palavraNormalizada = ingexNormalizarTexto(palavra).toLowerCase();
        if (!ingexDicionario.has(palavraNormalizada)) {
            ingexDicionario.set(palavraNormalizada, []);
        }
        ingexDicionario.get(palavraNormalizada).push(palavra);
    });

    return ingexDicionario;
}

// Função para carregar e interpretar o arquivo .aff
function ingexCarregarRegrasAfixos() {
    const affData = affptbr;
    const ingexRegras = {
        prefixos: [],
        sufixos: [],
    };

    const linhas = affData.split('\n');
    let tipoAtual = null;

    linhas.forEach(linha => {
        const partes = linha.split(/\s+/);
        const comando = partes[0];

        if (comando === 'PFX') {
            tipoAtual = 'prefixos';
            ingexRegras.prefixos.push(partes.slice(1));
        } else if (comando === 'SFX') {
            tipoAtual = 'sufixos';
            ingexRegras.sufixos.push(partes.slice(1));
        } else if (tipoAtual) {
            ingexRegras[tipoAtual].push(partes);
        }
    });

    return ingexRegras;
}

// Função para gerar variações das palavras com base nas regras do .aff
function ingexAplicarRegrasAfixos(palavra, regras) {
    let variacoes = new Set([palavra]);

    regras.prefixos.forEach(([_, add, ...resto]) => {
        if (resto.length === 0 || palavra.startsWith(resto[0])) {
            variacoes.add(add + palavra);
        }
    });

    regras.sufixos.forEach(([_, add, ...resto]) => {
        if (resto.length === 0 || palavra.endsWith(resto[0])) {
            variacoes.add(palavra + add);
        }
    });

    return variacoes;
}

// Função para verificar palavras e sugerir correções, priorizando acentuação e capitalização corretas
function ingexCorrigirTexto(texto, dicionario, regras) {
    const palavras = texto.split(' ');
    const sugestoes = [];

    palavras.forEach((palavra, index) => {
        const palavraLimpa = palavra.replace(/[.,?!;:]$/, ''); // Remover pontuação final
        const palavraLimpaNormalizada = ingexNormalizarTexto(palavraLimpa).toLowerCase();
        const variacoes = ingexAplicarRegrasAfixos(palavraLimpaNormalizada, regras);

        // Verificar se a palavra ou uma variação está no dicionário
        const encontrado = [...variacoes].some(variacao => dicionario.has(variacao));

        if (encontrado) {
            // Se a palavra foi encontrada, verificar as variações no dicionário
            const palavraDicionario = dicionario.get(palavraLimpaNormalizada);
            if (palavraDicionario && !palavraDicionario.includes(palavraLimpa)) {
                sugestoes.push({
                    palavraOriginal: palavra,
                    sugestoes: palavraDicionario // Sugere todas as variações do dicionário
                });
            }
        } else {
            // Se a palavra não foi encontrada, sugira correções
            const sugestao = ingexSugestaoCorrecao(palavraLimpaNormalizada, dicionario);
            sugestoes.push({
                palavraOriginal: palavra,
                sugestoes: sugestao
            });
        }
    });

    return sugestoes;
}

// Função para sugerir correções com base na distância de Levenshtein
function ingexSugestaoCorrecao(palavra, dicionario) {
    const sugestoes = [];

    dicionario.forEach((original, dicPalavra) => {
        const distancia = levenshtein.get(palavra, dicPalavra);
        sugestoes.push({ palavra: original, distancia });
    });

    // Ordenar por distância e retornar até 5 sugestões
    sugestoes.sort((a, b) => a.distancia - b.distancia);

    // Retornar as sugestões com acentuação preservada
    return sugestoes.slice(0, 5).flatMap(sugestao => sugestao.palavra);
}

// Carregar dicionário e regras de afixos
const ingexDicionario = ingexCarregarDicionario();
const ingexRegras = ingexCarregarRegrasAfixos();

const AutoSuggestion = (text) => {
    const resultado = ingexCorrigirTexto(text, ingexDicionario, ingexRegras);
    console.info({ resultado })
    return resultado;
}

export default AutoSuggestion;
