function isColorDark(color) {
    let r, g, b;

    // Verifica se a cor está no formato rgba
    if (color.startsWith('rgba') || color.startsWith('rgb')) {
        // Extrai os valores de r, g e b da string rgba
        const rgbaValues = color.match(/\d+/g).map(Number);
        r = rgbaValues[0];
        g = rgbaValues[1];
        b = rgbaValues[2];
    }
    // Verifica se a cor está no formato hexadecimal
    else if (color.startsWith('#')) {
        const hex = color.replace('#', '');
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    }

    // Calcula o brilho da cor
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Retorna true se a cor for escura, false se for clara
    return brightness < 128;
}

export default isColorDark;